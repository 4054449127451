import React, {useState} from 'react';
import Nav from '../../Nav';
import WalNav from '../../WalNav';
import Gento from '../GenTo';
import { Link } from 'react-router-dom';
const GenToken = ()=> {

 
    const dto = 'none';
    const dop = 'block';

    const [dtoken, setDtoken] = useState(dto);
    
    const genToken = ()=>{
        setDtoken(dop);
    }

    const doff = 'none';
    const dopp = 'block';
       const lighter = 'lightgrey';
       const  redi = '#47a1ff';
    const [po, setPo] = useState(lighter)
    const [def, setDef] = useState(doff);

    const poi = ()=> {
        setPo(redi);
        setDef(dopp);
    }

    return(
        <>
 <div className="d-flex">
       <Nav/>

<section className="home_contents">

    <WalNav/>
   <div className="container presaleWrap mx-auto row">
    <div className="col-12 col-md-4">
    <div className="bnbButtonWrapper my-3">

    <label for="exampleInputEmail1 my-2">Token Type <span style={{color:"red"}}>*</span></label>
           <ul className="tokengen">
            <li><Link onClick={()=> genToken()}>Liquidity Genaretor Token</Link>
            <ul className="subtoken" style={{display:dtoken}}>
            <li><Link to="/tokenbuilder">Standard Token</Link></li>
            {/* <li><Link to='/babytoken'>Baby Token</Link></li> */}
            <li><Link to="/buybacktoken">Buyback Baby Token</Link></li>
            </ul>
            </li>
              
           </ul>
                    
         
           
           <div className="bnbshowoff" >
               Fee 0.2 BNB
           </div>
           
       </div>
    </div>
 


    
  <Gento />


      



    </div>

    </section>

    </div>
        
        </>
    )
}

export default GenToken;