import React, {useState, useContext} from 'react';
import Nav from '../partial/Nav';
import WalNav from '../partial/WalNav';
import { Link } from 'react-router-dom';
import { ContextApi } from '../../utilities';
import { ethers } from 'ethers';


const Token  = ()=> {
    const {locks} = useContext(ContextApi)
    const dn = "none";
    const dop = "block";
    const[op, setOp] = useState(dop);

    return(
        <>
        <div className="d-flex">
       <Nav/>

<section className="home_contents">
  
    <WalNav/>
   <div className="container mt-5 mx-auto ">
      
      <div className="searchBox">
      <form>
<div className="form-group mt-2">
{/* <input type="text"  className="form-control preinput"   placeholder="Search By Token Address"/> */}


</div>
</form>
<div className="btnWraper">
<button onClick={()=> setOp(dop)}>All</button>
<button  onClick={()=> setOp(dn)}>My Locks</button>

</div>
      </div>

<div className="tableLocker" style={{display:op, marginTop:"40px"}}>
<div style={{display:'flex', justifyContent:"space-between", paddingTop:"10px"}}><span style={{fontWeight:"bold"}}>Token</span> <span style={{fontWeight:"bold"}}>Locked Amount</span> <span>.</span></div>
  {
    locks && locks.map((lock, indx)=>(
        <div key={indx} className="tableChild">
        <div className="tableLeft">
           {lock.description}
        </div>
         
         <div className="tableMiddle">
            <h5>{ethers.utils.formatEther(lock.amount)}</h5>
         </div>

         <div className="tableRight">
            <Link to={`/innerinfo?id=${indx}&&tokenTitle=${lock.description}&&tokenAmount=${ethers.utils.formatEther(lock.amount)}&&tokenAddress=${lock.token}&&unlockDate${lock.unlockDate}`}> View</Link>
         </div>

    </div>
    ))
  }



</div>










   </div>
   </section>
   </div>
        </>
    )
}

export default Token;