

import React, {useContext, useState} from 'react';
import { ContextApi } from '../../../utilities';
import ConnectButton from '../../../walletBtn';

const PreFourthPage = ({pdn3, pBtn3})=>{

     const {
          isCo,
          logoUrl, setLogoUrl,
          bannerUrl, setBannerUrl,
          website, setWebsite,
          promoVid, setPromoVid,
          discord, setDiscord,
          whitepaper, setWhitePaper,
          facebook, SetFaceBook,
          twitter, setTwitter,
          github,setGithub,
          telegram,  setTelegram,
          description, setDescription

     } = useContext(ContextApi);

    


    return(
        <>
        <div className="col-12 col-md-12 col-lg-8 col-xl-8  rightPresale" style={{display:pdn3}}>
     
     <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Logo URL <span style={{color:"red"}}>*</span></label>
<input type="text" value={logoUrl} onChange={(e)=>setLogoUrl(e.target.value)} className="form-control preinput" id="exampleInputEmail1"   placeholder="https://website.com/logo.png"/>

</div>



</form>


     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
   

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Banner URL <span style={{color:"red"}}>*</span></label>
<input value={bannerUrl} onChange={(e)=>setBannerUrl(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="https://website.com/banner.png"/>

</div>




</form>


     </div>
    
    
    
    
    </div>



    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Website<span style={{color:"red"}}>*</span></label>
<input value={website} onChange={(e)=>setWebsite(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="https:website.com"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div style={{position:'relative'}} className="form-group">
<label for="exampleInputEmail1">Promo Video <span style={{color:"red"}}>*</span></label>
<input style={{color:'white'}} value={promoVid} onChange={(e)=>setPromoVid(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="https://www.youtube.com/watch?v=Rq-jV5yHt-s"/>
{
     promoVid != ''?<span>make sure in the video link have this <span style={{color:'red'}}>watch?v=</span> video link should be something like this <span style={{color:'red'}}>https://www.youtube.com/watch?v=Rq-jV5yHt-s</span></span>
:''
}

</div>
</form>


     </div>
    </div>



    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Discord<span style={{color:"red"}}>*</span></label>
<input value={discord} onChange={(e)=>setDiscord(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">whitepaper <span style={{color:"red"}}>*</span></label>
<input value={whitepaper} onChange={(e)=>setWhitePaper(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>


     </div>
    </div>





    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Facebook<span style={{color:"red"}}>*</span></label>
<input value={facebook} onChange={(e)=>SetFaceBook(e.target.value)} type="text"  className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Twitter <span style={{color:"red"}}>*</span></label>
<input value={twitter} onChange={(e)=>setTwitter(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>


     </div>
    </div>





    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Github<span style={{color:"red"}}>*</span></label>
<input value={github} onChange={(e)=>setGithub(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Telegram <span style={{color:"red"}}>*</span></label>
<input value={telegram} onChange={(e)=>setTelegram(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>


     </div>
    </div>




    <form>
<div className="form-group">
<label for="exampleInputEmail1">Description <span style={{color:"red"}}>*</span></label>
<textarea value={description} onChange={(e)=>setDescription(e.target.value)} style={{height:"150px"}} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"></textarea>
</div>
</form>
    
{
     description.length < 150? <p style={{color:'red'}}>description should be 150 characters minimum</p>:''
}
    

   
{
     isCo() == null ?  <>
     {
          logoUrl != '' && description != '' && description.length >= 150 && website != '' && bannerUrl != '' ?
          <button onClick={()=> pBtn3()} className="nextBtn" >
     Next
  </button>:<button disabled style={{background:'gray'}} className="nextBtn" >
     Next
  </button>
     }
     </>:<div style={{padding:'10px 0px'}}><ConnectButton/></div>
}

</div>



    
        </>
    )
}

export default PreFourthPage;