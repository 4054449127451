import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { ContextApi } from '../../utilities';
const SecondNav = ()=> {
    const {presaleDetails} = useContext(ContextApi);
     
    const moData = presaleDetails?.slice(-3)
    return(
        <>
         <div className="sNav">
         <Link to="#" style={{color:"#11b1F5"}}><i className="fa-solid fa-fire-flame-curved" ></i> Trending</Link>

         {
            moData && moData.map((pool, indx)=>(
                <Link to={`/detail?token=${pool.PresaleAddress}&&owner=${pool.owner}`} style={{color:"#111"}}>#{pool.tokenSymbol}</Link>
            ) )
         }
         {/* <Link to="/detail" style={{color:"#111"}}>#2 CAKE</Link>
         <Link to="/detail" style={{color:"#111"}}>#3 TINT</Link> */}

      </div> 
        </>
    )
}

export default SecondNav;