
import React from 'react';



const Footer = ()=>{

    return(
        <>
        <div style={{width:'100%', padding:'60px 9px', display:'flex', alignItems:'center' , justifyContent:'center',height:'90px'}} >
            <div className="footer_sub_wrapper" style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-around'}}>
               
                <div className="footer_devide sociallll" style={{width:'100%', display:'flex', flexDirection:'column',  alignItems:'center'}}>
                 
                    <div id="mainSocial" className="mainSocial">
           <a href="https://twitter.com/taskobey"><img style={{borderRadius:'50%', width:'35px',  height:'35px'}} src="images/tw.png"alt="twiterr"/></a>
                <a href="https://t.me/taskobey"><img style={{borderRadius:'50%', marginLeft:'30px', margin:'0px 5px', width:'35px',  height:'35px'}}  src="images/t.png"alt="twiterr"/></a>
              
           </div>
          
            </div>


                </div>
            </div>
     
        </>
    )
}


export default Footer;