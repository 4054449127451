
import { useContext } from 'react';
import { ContextApi } from '../../../utilities';
 

const Gento = ()=>{
    const {
        liqName , setLiqName,
        liqSymbol, setLiqSymbol,
        liqSupply, setLiqSupply,
        liqMarketingAddress, setLiqMarketingAddress,
        liqMarketingParcent, setLiqMarketingMarcent, 
        createLiq,
        isCo
    } = useContext(ContextApi)
    
    const crateToken =()=>{
        alert ("only completed UI here is don't have any backend or solidty smart contract Work")
    }
    return(
        <>
          <div className="col-12 col-md-8  rightPresale" >
    
    <div className="row">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
      
 <div className="form-group">
 <label for="exampleInputEmail1">Name <span style={{color:"red"}}>*</span></label>
 <input onChange={(e)=>setLiqName(e.target.value)} value={liqName} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>

      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
    
 <div className="form-group">
 <label for="exampleInputEmail1">Symbol <span style={{color:"red"}}>*</span></label>
 <input onChange={(e)=>setLiqSymbol(e.target.value)} value={liqSymbol} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex : "/>
 </div>
 
 
 
      </div>
     </div>
    
     <div className="row mt-3">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
    
 <div className="form-group">
 <label for="exampleInputEmail1">Total Supply <span style={{color:"red"}}>*</span></label>
 <input value={liqSupply} onChange={(e)=>setLiqSupply(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>

      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
      <form>
 <div className="form-group">
 <label for="exampleInputEmail1">Router <span style={{color:"red"}}>*</span></label>
 <select className="form-control preinput" style={{border:'1px solid lightgrey'}}>
    <option>PancakeSwap</option>
   
 </select>
 </div>
 </form>
 
 
      </div>
     </div>



     <div className="row mt-3">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
      
 <div className="form-group">
 <label for="exampleInputEmail1">Charity/Marketing address <span style={{color:"red"}}>*</span></label>
 <input onChange={(e)=> setLiqMarketingAddress(e.target.value)} value={liqMarketingAddress} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>

      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
    
 <div className="form-group">
 <label for="exampleInputEmail1">Charity/Marketing percent (%) <span style={{color:"red"}}>*</span></label>
 <input value={liqMarketingParcent} onChange={(e)=> setLiqMarketingMarcent(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex : "/>
 </div>
 
 
 
      </div>
     </div>
 
 
        {
            isCo() != null ? isCo():
            <button className="nextBtn" onClick={createLiq} style={{width:'128px'}}>Create Token</button>
 
        }
 
 </div>
        </>
    )
}

export default Gento;