

import React , {useContext} from 'react';
import { ContextApi } from '../../../utilities';
import ConnectButton from '../../../walletBtn';

const PreThirdPage = ({pdn1, pBtn1})=>{
  
  const {
    fbuyback ,setFbuyback,
    sellingAmount, setSellingAmount,
    fsoftCap, setSsoftCap, 
    liquidityPercentage, setLiquidityParcentage,
    fPresaleStart, setFpresaleStart,
    fPresaleEnd, setFpresaleEnd,
    fwhite, setFwhite,
    isCo,
    tokenSymbol1

  } = useContext(ContextApi);



    return(
        <>
              <div className="col-12 col-md-12 col-lg-8 col-xl-8  rightPresale" style={{display:pdn1}}>
          



              <div className="row">
              <div className="col-12">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1"> Total selling amount  <span style={{color:"red"}}>*</span></label>
          <input required value={sellingAmount} onChange={(e)=> setSellingAmount(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder=" 1000"/>
      
       
        </div>
        

        
      </form>


              </div>

              {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <p>.</p>

              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Total Token <span style={{color:"red"}}>*</span></label>
          <input value={listingRate} onChange={(e)=> setListingRate(e.target.value)}  type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="24700"/>
          <span style={{color:'lightblue'}}>
        Total {tokenName } {' '} Want to sell
      
        </span>
       
        </div>
      


        
      </form>


              </div>
              
               */}
              </div>


              <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Softcap BNB <span style={{color:"red"}}>*</span></label>
          <input value={fsoftCap} onChange={(e)=> setSsoftCap(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder=" 0.2 BNB"/>
        </div>
        

        
      </form>


              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <p > .</p>
               

              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Buy Back Fee <span style={{color:"red"}}>*</span></label>
          <input value={fbuyback} onChange={(e)=> setFbuyback(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder=" 3 BNB"/>
        </div>
      


        
      </form>


              </div>
              
              
              
              
              </div>

              <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Liquidity Parcentage <span style={{color:"red"}}>*</span></label>
          <input value={liquidityPercentage} onChange={(e)=> setLiquidityParcentage(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder=" 51%"/>
        </div>
          </form>
              </div>

            
              </div>

              <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Start Time <span style={{color:"red"}}>*</span></label>
          <input value={fPresaleStart} onChange={(e)=> setFpresaleStart(e.target.value)} type="datetime-local" className="form-control preinput" id="exampleInputEmail1" />
        </div>
          </form>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <p>.</p>

              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">END Time <span style={{color:"red"}}>*</span></label>
          <input value={fPresaleEnd} onChange={(e)=> setFpresaleEnd(e.target.value)} type="datetime-local" className="form-control preinput"    />
        </div>
        </form>


              </div>
              </div>


              {/* <form>
        <div className="form-group  mt-4">
          <label for="exampleInputEmail1">Vesting period each cycle<span style={{color:"red"}}>*</span></label>
          <input type="text" className="form-control preinput"   placeholder="  .3 BNB"/>
        </div>
          </form>

          <form>
        <div className="form-group  mt-4">
          <label for="exampleInputEmail1">Unlock KYC <span style={{color:"red"}}>*</span></label>
          <input type="text" className="form-control preinput"   placeholder=" Ex:"/>
        </div>
          </form> */}

{/* 
          <form>
        <div className="form-group  mt-4">
          <label for="exampleInputEmail1">Unlock Audit<span style={{color:"red"}}>*</span></label>
          <input type="text" className="form-control preinput"   placeholder="EX: "/>
        </div>
          </form> */}

        <div className="fo mt-2">
        <input checked={fwhite} onChange={(e)=>setFwhite(e.target.checked)} type="radio" /> Use WhiteList
        <p>You can enable/disable whitelist anytime.</p>
        </div>

      {
        fsoftCap != '' && liquidityPercentage != '' && sellingAmount != ''? <p style={{fontSize:'17px', margin:'10px 0px', color:'skyblue'}}>
        you have to spend this {sellingAmount} amount of {tokenSymbol1} token
      </p>:''
      }

            {
              isCo() == null? <>
              {
                sellingAmount !=='' &&  fPresaleStart !== '' && fPresaleEnd !== ''&&
                liquidityPercentage !== '' && fbuyback !== '' ?
                <button onClick={()=> pBtn1()} className="nextBtn" >
                Next
               </button>
                :<button disabled style={{background:'gray'}} className="nextBtn" >
                Next
            </button>
           
              }
                
              </>:<ConnectButton/>
            }
          </div>
    
    

        </>
    )
}

export default PreThirdPage;