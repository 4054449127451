import { createContext, useState, useEffect } from "react";
import { ethers} from "ethers";
import {useWriteContract,useAccount} from 'wagmi'
import TokenAbiForApprove from './abis/TokenAbiForApprove.json'
import PresaleFactoryAbi from './abis/PresaleFactory.json'
import PresaleAbi from './abis/Presale.json'
import ConnectButton from "./walletBtn";
import lockerAbi from './abis/Locker.json'
import standardTokenAbi from './abis/TokenFactory.json'
// import babyFactoryAbi from './abis/BabyFactory.json'
import buybackFactoryAbi from './abis/BuybackFactory.json'
import liquidityFactoryAbi from './abis/LiquidityGenFactory.json'
import fairAbi from './abis/FairlaunchFactory.json';


export const ContextApi = createContext();

    

const ContextComponent = ({children})=>{

    const {address} = useAccount();
    const PresaleFactoryABI = PresaleFactoryAbi.abi;
    const TokenABI = TokenAbiForApprove
    const PresaleABI = PresaleAbi.abi;
    const LockerABI = lockerAbi.abi;
   
    const {writeContractAsync} = useWriteContract();

    const PresaleFactoryAddress = '0x56dF924AFc2Fdbb613b65686780Cf4c98A503D29'
    const LockerAddress = '0xe9D3BA67B004E4EB7aAAE1da63E52dDa7f7aB567'

  

    const [getToken, setGetToken] = useState('')
    const [tokenName, setTokenName] = useState('')
    const [tokenSymbol, setTokenSymbol] = useState('')
    const [tokenSupply, setTokenSupply] = useState('')
    const [tokenDecimals, setTokenDecimals] = useState('')
    const [tokenBalance, setToekBalance] = useState('')
   const [userTokenBal, setUserTokenBal] = useState('')

    const [abistatus, setAbistatus] = useState(false);
    const [hardCap , setHardCap] = useState('');
    const [softCap, setSoftCap] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('')

    const [minBuy, setMinBuy] = useState('');
    const [maxBuy, setMaxBuy] = useState('');
    const [totalToken, setTotalToken] = useState('');
    const [presaleRate, setPresaleRate] = useState('');
    const [listingRate, setListingRate] = useState('');
    const [whitelist, setWhitelist] = useState(false)
    const [PresalePool ,setPresalePool] = useState(null);
    
 
    const [logoUrl, setLogoUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [website, setWebsite] = useState('');
    const [promoVid, setPromoVid] = useState('');
    const [discord, setDiscord] = useState('');
    const [whitepaper, setWhitePaper] = useState('');
    const [facebook, SetFaceBook] = useState('');
    const [twitter,setTwitter] = useState('');
    const [github, setGithub] = useState('');
    const [telegram, setTelegram] = useState('');
    const [description, setDescription] = useState('');

    const [ltoken, setLtoken] = useState('');
    const [tokenTitle, setTokenTitle] = useState('');
    const [ltokenAmount, setLtokenAmount]  = useState('');
    const [tokenLockDate, setTokenLockDate] = useState('')

    const [locks, setLocks] = useState(null)
  
   const createLock = async()=>{
    try{
        const lockTime = Math.floor(new Date(tokenLockDate).getTime()/1000)

       const txt = await writeContractAsync({
        address:ltoken,
        abi:TokenABI,
        functionName:'approve',
        args:[LockerAddress, ethers.utils.parseEther(ltokenAmount)]
       })

        const tx = await writeContractAsync({
            address:LockerAddress,
            abi:LockerABI,
            functionName:'lock',
            args:[address, ltoken, ethers.utils.parseEther(ltokenAmount), lockTime, tokenTitle]
        })

        alert("your token Locked successfully")
    }catch(error){
       
        console.log(error)
    }
   }

   useEffect(()=>{
      async function getLocks(){
          const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org')
          const contract = new ethers.Contract(LockerAddress, LockerABI, provider);

          const allloks = await contract.getallLocks()
           setLocks(allloks)
      
        }   
      getLocks()
   },[])


useEffect(() => {
    const getPool = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
          
            const contract = new ethers.Contract(PresaleFactoryAddress, PresaleFactoryABI, provider);
             
            const data = await contract.getAll();
             setPresalePool(data)
        } catch(error) {
            console.log('Error fetching pools:', error);
        }
    }
    getPool();

}, []);
    const getTokenInfo = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
           
            const contract = new ethers.Contract(PresaleFactoryAddress, PresaleFactoryABI, provider);

            const result = await contract.tokenInfo(getToken, address?address:'0xe4e7a312CCDd664CC19DCfD5b1f3af5b5c81D3ce');
            
            
            if (result) {
                setTokenName(result[1]);
                setTokenSymbol(result[2]);
                const totalSupplyInEther = ethers.utils.formatEther(result[0]);
                const userTokenBal = ethers.utils.formatEther(result[5])
                setUserTokenBal(userTokenBal)
                const decimalInEther = result[3];
                const userBal = ethers.utils.formatEther(result[4]);
                setTokenDecimals(decimalInEther.toString());
                setToekBalance(userBal.toString())
                setTokenSupply(totalSupplyInEther.toString());
                setAbistatus(true);
            }
        } catch (error) {
            console.error('Error retrieving token info:', error);
           
        }
    };
    useEffect(() => {
        getTokenInfo();
    }, [getToken]);


      useEffect(()=>{
        setTotalToken(hardCap * presaleRate)
      },[hardCap, presaleRate])
    
    const [isPresaleError, setIsPresaleError] = useState(false)
    const creatPool = async () => {
        try {
        
            const tokenSp = ethers.utils.parseEther(String(totalToken));
            const presale= '0x56dF924AFc2Fdbb613b65686780Cf4c98A503D29'
            // Approve tokens
            const approveTx = await writeContractAsync({
                address: getToken,
                abi: TokenABI,
                functionName: 'approve',
                args: [presale, tokenSp]
            });
    
            const feeInBNB = 0.2;
            const metadata = `${logoUrl.toString()}~${bannerUrl.toString()}~${website.toString()}~${promoVid.toString()}~${discord.toString()}~${whitepaper.toString()}~${facebook.toString()}~${twitter.toString()}~${github.toString()}~${telegram.toString()}~${description.toString()}`;
    
            if(approveTx){
                   // Create the presale
            const tx = await writeContractAsync({
                address: presale,
                abi: PresaleFactoryABI,
                functionName: 'create',
                args: [
                    ethers.utils.parseEther(softCap),
                    ethers.utils.parseEther(hardCap),
                    ethers.utils.parseEther(maxBuy),
                    ethers.utils.parseEther(minBuy),
                    Math.floor(new Date(startTime).getTime() / 1000),
                    Math.floor(new Date(endTime).getTime() / 1000),
                    ethers.utils.parseEther(presaleRate),
                    getToken,
                    whitelist,
                    metadata,
                    ethers.utils.parseEther(String(totalToken))
                ],
                value:ethers.utils.parseEther(feeInBNB.toString())
            });

            alert("Created Successfully");
            setIsPresaleError(false)
            setTimeout(()=>{
                window.location.href = '/viewpools'
            },2000)
    
            }



         
        } catch (error) {
            if (error) {
                setIsPresaleError(true)
            }
            console.log('Error while creating pool', error);
        }
    };
    


    const [presaleDetails, setPresaleDetails] = useState([]);
    useEffect(() => {
        const getPoolDetails = async () => {
            try {
              const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
            
              for (const pool of PresalePool) {
                const contract = new ethers.Contract(pool.token, PresaleABI, provider);
                const softCap = await contract.getSoftCap();
                
                const hardCap = await contract.getHardCap();
                const startTime = await contract.getStartTime();
                const endTime = await contract.getEndTime();
                const tokenRate = await contract.getTokenRate()
                const owner = pool.owner;
                const isCancel = await contract.getIsCancel();
                const symbol = await contract.getTokenSymbol();
                const name = await contract.getTokenName();
          
                const startBn = ethers.BigNumber.from(startTime);
                const endBn = ethers.BigNumber.from(endTime);

                const startFormatTime = new Date(startBn.toNumber() * 1000);
                const endFormatTime = new Date(endBn.toNumber() * 1000);

                const timeDifferenceInSeconds = Math.floor((startFormatTime.getTime() - Date.now()) / 1000);
          
                const days = Math.floor(timeDifferenceInSeconds / (24 * 3600));
                const hours = Math.floor((timeDifferenceInSeconds % (24 * 3600)) / 3600);
                const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
                const seconds = Math.floor(timeDifferenceInSeconds % 60);
          
                // Format the time remaining string
                const timeRemainingString = `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          
                // Format the end time in the same way
                const endTimeDifferenceInSeconds = Math.floor((endFormatTime.getTime() - Date.now()) / 1000);
                const endDays = Math.floor(endTimeDifferenceInSeconds / (24 * 3600));
                const endHours = Math.floor((endTimeDifferenceInSeconds % (24 * 3600)) / 3600);
                const endMinutes = Math.floor((endTimeDifferenceInSeconds % 3600) / 60);
                const endSeconds = Math.floor(endTimeDifferenceInSeconds % 60);
                const endTimeRemainingString = `${endDays.toString().padStart(2, '0')}:${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}:${endSeconds.toString().padStart(2, '0')}`;
                const totalRaised = await contract.totalRaised();
                const metadata = await contract.getMetadata();
               const rate =ethers.utils.formatEther(tokenRate)
             
               
               const progress = ethers.utils.formatEther(softCap)*ethers.utils.formatEther(totalRaised);
               const pro = progress.toFixed(2)
               
      
                const PresaleObject = {
                  PresaleAddress: pool.token,
                  softCap: ethers.utils.formatEther(softCap),
                  hardCap: ethers.utils.formatEther(hardCap),
                  startTime: timeRemainingString,
                  endTime: endTimeRemainingString,
                  isCancel:isCancel, 
                  tokenRate:rate,
                  tokenName:name,
                  tokenSymbol:symbol,
                  totalRaised:ethers.utils.formatEther(totalRaised),
                  bnbRaised:pro,
                  logo:metadata?.split('~')[0],
                  owner:owner
                };
          
                setPresaleDetails(prevDetails => [...prevDetails, PresaleObject]);
              }
            } catch (error) {
              console.log('Error fetching pool details:', error);
            }
          };
          
      getPoolDetails();
    }, [PresalePool]);
    const isCo = ()=>{
        if(address == null){
         return <div style={{padding:'10px 0px'}}><ConnectButton/></div>;
        }else{
            return null
        }
    }


    const router = '0x1A0A18AC4BECDDbd6389559687d1A73d8927E416';

    
    const [standardName, setStandardName] = useState('');
    const [standardSymbol, setStandardSymbol] = useState('');
    const [standardDecimals, setStandardDecimals] = useState('');
    const [standardSupply, setStandardSupply] = useState('');
    const standardABI = standardTokenAbi.abi;
    const standardFactoryAddress = '0x84a19814cFeF9ee7c1eAd5e0A303D80763d3EAE2'
    const createStandard = async ()=>{
        try{
          
            const tx = await writeContractAsync({
                address:standardFactoryAddress,
                abi:standardABI,
                functionName:'createStandard',
                args:[standardName, standardSymbol, standardDecimals, ethers.utils.parseEther(String(standardSupply))]
                ,value:ethers.utils.parseEther('0.2')
            })
          
           if(tx){
            setTimeout(()=>{
                window.location.href = `/learn?hash=${tx}`
            },5000)
           }

        }catch(error){
            if(error){
                alert("insufficient BNB check your balance please make sure you have 0.2 BNB")
            }
            console.log(error)
        }
    }
    
    
    // const babyFactoryABI = babyFactoryAbi.abi;
    // const babyFactoryAddress = '0x86Fa6BbD8d6C452B7206708925b492683a981433'

   
    const liquidityFactoryABI = liquidityFactoryAbi.abi;
    const liquidityFactoryAddress = '0x68e794950427eB8B4749584555C998FC52c89d13'
    const [liqName , setLiqName] = useState('');
    const [liqSymbol, setLiqSymbol] = useState('');
    const [liqSupply, setLiqSupply] = useState('');
    const [liqMarketingAddress, setLiqMarketingAddress] = useState('');
    const [liqMarketingParcent, setLiqMarketingMarcent] = useState('');
    const createLiq = async()=>{
        try{
        
            const tx = await writeContractAsync({
                address :liquidityFactoryAddress,
                abi:liquidityFactoryABI,
                functionName:'createLiquidityGeneratorToken',
                args:[liqName, liqSymbol, ethers.utils.parseEther(liqSupply),router, liqMarketingAddress,liqMarketingParcent, liqMarketingParcent, liqMarketingParcent ], 
                value:ethers.utils.parseEther('0.2')
            })

            if(tx){
                setTimeout(()=>{
                    window.location.href = `/learn?hash=${tx}`
                },5000)
               }
        }catch(error){
            if(error){
                alert("insufficient BNB check your balance please make sure you have 0.2 BNB")
            }
            console.log(error)
        }
    }

    const buybackFactoryABI = buybackFactoryAbi.abi;
    const buybackFactoryAddress = '0x35A7aeBC571Ba230bBE53918E9A2DdFd225b913F'
    const [buyName, setBuyName] = useState('');
    const [buySymbol, setBuySymbol] = useState('');
    const [buySupply, setBuySupply] = useState('');
    const [buyReward, setBuyReward] = useState('');
    const [buyLiqFee, setBuyLiqFee] = useState('');
    const [buyMarkFee, setBuyMarkFee] = useState('');
    const [buyBuyFee, setBuyBuyFee] = useState('');
    const [buyRefFee, setBuyRefFee] = useState('');
    const createBuyback = async () => {
        // Convert the fee values to integers
        const fees = [
            parseInt(buyLiqFee, 10) || 0,
            parseInt(buyBuyFee, 10) || 0,
            parseInt(buyRefFee, 10) || 0,
            parseInt(buyMarkFee, 10) || 0,
            // Assuming the last fee is always 0 as per your contract structure
        ];

      

        try {
            const tx = await writeContractAsync({
                address: buybackFactoryAddress,
                abi: buybackFactoryABI,
                functionName: 'createBuybackBabyToken',
                args: [
                    buyName,
                    buySymbol,
                    ethers.utils.parseUnits(buySupply, 18), // Adjust this if your token has different decimals
                    buyReward,
                    router,
                    fees
                ],
                value: ethers.utils.parseEther('0.2') // This is fine if you are sending 0.2 BNB
            });

            if (tx) {
                setTimeout(() => {
                    window.location.href = `/learn?hash=${tx.hash}`;
                }, 5000);
            }
        } catch (error) {
            if(error){
                alert("insufficient BNB check your balance please make sure you have 0.2 BNB")
            }
            console.error('Transaction failed:', error );

        }
    };

    
     const fairABI = fairAbi.abi;
     const fairAddress = '0x3410ec66fBA4E8B34e40A00ed067B8432056cd31';
     const wrapBNB = '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd';
    const [fToken,setFtoken] = useState('')
    const [fbuyback ,setFbuyback] = useState('');
    const [sellingAmount, setSellingAmount] = useState('');
    const [fsoftCap, setSsoftCap]  = useState('');
    const [liquidityPercentage, setLiquidityParcentage] = useState('');
    const [fPresaleStart, setFpresaleStart] = useState('');
    const [fPresaleEnd, setFpresaleEnd] = useState('');
    const [fwhite, setFwhite] = useState(false)
  
     const fstart = Math.floor(new Date(fPresaleStart).getTime()/1000);
     const fend = Math.floor(new Date(fPresaleEnd).getTime()/1000);

     const [logoUrl1, setLogoUrl1] = useState('');
     const [bannerUrl1, setBannerUrl1] = useState('');
     const [website1, setWebsite1] = useState('');
     const [promoVid1, setPromoVid1] = useState('');
     const [discord1, setDiscord1] = useState('');
     const [whitepaper1, setWhitePaper1] = useState('');
     const [facebook1, SetFaceBook1] = useState('');
     const [twitter1,setTwitter1] = useState('');
     const [github1, setGithub1] = useState('');
     const [telegram1, setTelegram1] = useState('');
     const [description1, setDescription1] = useState('');
   

    //  const createFairlaunch = async()=>{
    //     try{
    //         const tkn = ethers.utils.parseEther(String(sellingAmount))
    //         const appro = await writeContractAsync({
    //              address:fToken,
    //              abi:TokenABI,
    //              functionName:'approve',
    //              args:[fairAddress, tkn]
    //         })


    //         const metadata = `${logoUrl1.toString()}~${bannerUrl1.toString()}~${website1.toString()}~${promoVid1.toString()}~${discord1.toString()}~${whitepaper1.toString()}~${facebook1.toString()}~${twitter1.toString()}~${github1.toString()}~${telegram1.toString()}~${description1.toString()}`;
           
    //        const tx =  await writeContractAsync({
    //         address:fairAddress, 
    //         abi:fairABI,
    //         functionName:'create', 
    //         args:[fToken,wrapBNB,false, ethers.utils.parseEther(fbuyback), tkn, ethers.utils.parseEther(fsoftCap), ethers.utils.parseEther(liquidityPercentage), fstart, fend, metadata],
    //         value:ethers.utils.parseEther('0.2')

    //        })
    //     }catch(error){
    //         console.log(error,'error from create fairlaunch')
    //     }
    //  }

     const [tokenName1, setTokenName1] = useState('')
     const [tokenSymbol1, setTokenSymbol1] = useState('')
     const [tokenDecimals1, setTokenDecimals1] = useState('')
     const [tokenBalance1, setToekBalance1] = useState('');
     const [tokenSupply1, setTokenSupply1] = useState('');
     const [abistatus1, setAbistatus1] = useState(false);

     const getFiarTokenInfo = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
           
            const contract = new ethers.Contract(PresaleFactoryAddress, PresaleFactoryABI, provider);

            const result = await contract.tokenInfo(fToken, address?address:'0xe4e7a312CCDd664CC19DCfD5b1f3af5b5c81D3ce');
            
            
            if (result) {
                setTokenName1(result[1]);
                setTokenSymbol1(result[2]);
                const totalSupplyInEther = ethers.utils.formatEther(result[0]);
                const decimalInEther = result[3];
                const userBal = ethers.utils.formatEther(result[4]);
                setTokenDecimals1(decimalInEther.toString());
                setToekBalance1(userBal.toString())
                setTokenSupply1(totalSupplyInEther.toString());
                setAbistatus1(true);
            }
        } catch (error) {
            console.error('Error retrieving token info:', error);
           
        }
    };
    useEffect(() => {
        getFiarTokenInfo();
    }, [fToken]);

  

    const setTakeFeeAddress = async () => {
        try {
            // Verify ABI and address are correctly defined
            if (!PresaleFactoryAddress) {
                throw new Error('PresaleFactoryAddress is undefined');
            }
          
            // Logging ABI and address
            console.log('PresaleFactoryAddress:', PresaleFactoryAddress);
            console.log('PresaleABI:', PresaleABI);
    
            const tx = await writeContractAsync({
                address: buybackFactoryAddress,
                abi: buybackFactoryABI,
                functionName: 'setFeeTo',
                args: ['0xe4e7a312CCDd664CC19DCfD5b1f3af5b5c81D3ce'],
            });
    
            if (tx) {
                alert('done');
            } else {
                alert('something went wrong');
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    


    return(
     
        <ContextApi.Provider value={{
            setTakeFeeAddress,
            logoUrl1, setLogoUrl1,
            bannerUrl1, setBannerUrl1,
            website1, setWebsite1,
            promoVid1, setPromoVid1,
            discord1, setDiscord1,
            whitepaper1, setWhitePaper1,
            facebook1, SetFaceBook1,
            twitter1, setTwitter1,
            github1,setGithub1,
            telegram1,  setTelegram1,
            description1, setDescription1,



            tokenName1, setTokenName1,
            tokenSymbol1, setTokenSymbol1,
            tokenDecimals1, setTokenDecimals1,
            tokenBalance1, setToekBalance1,
            tokenSupply1, setTokenSupply1,
            abistatus1, setAbistatus1,

            fToken,setFtoken,
            fbuyback ,setFbuyback,
            sellingAmount, setSellingAmount,
            fsoftCap, setSsoftCap, 
            liquidityPercentage, setLiquidityParcentage,
            fPresaleStart, setFpresaleStart,
            fPresaleEnd, setFpresaleEnd,
            fwhite, setFwhite,
            // createFairlaunch,


            buyName , setBuyName,
            buySymbol, setBuySymbol,
            buySupply, setBuySupply,
            buyReward, setBuyReward,
            buyLiqFee, setBuyLiqFee,
            buyMarkFee, setBuyMarkFee,
            buyBuyFee, setBuyBuyFee,
            buyRefFee, setBuyRefFee,
            createBuyback,



            liqName , setLiqName,
            liqSymbol, setLiqSymbol,
            liqSupply, setLiqSupply,
            liqMarketingAddress, setLiqMarketingAddress,
            liqMarketingParcent, setLiqMarketingMarcent, 
            createLiq,

            standardName, setStandardName,
            standardSymbol, setStandardSymbol,
            standardDecimals, setStandardDecimals,
            standardSupply, setStandardSupply,
            createStandard,
            locks,
            createLock,
            tokenLockDate, setTokenLockDate,
            ltokenAmount, setLtokenAmount,
            tokenTitle, setTokenTitle,
            ltoken, setLtoken,
            logoUrl, setLogoUrl,
            bannerUrl, setBannerUrl,
            website, setWebsite,
            promoVid, setPromoVid,
            discord, setDiscord,
            whitepaper, setWhitePaper,
            facebook, SetFaceBook,
            twitter, setTwitter,
            github,setGithub,
            telegram,  setTelegram,
            description, setDescription,
            isCo,
            presaleDetails,
            PresalePool,
            whitelist,
            setWhitelist,
            tokenBalance, 
            tokenDecimals,
            tokenName,
            tokenSymbol,
            tokenSupply,
            abistatus,
            getToken,
            setGetToken,
            creatPool,
            softCap,
            hardCap,
            startTime,
            endTime,
            minBuy,
            maxBuy,
            totalToken,
            presaleRate,
            listingRate,
            setSoftCap,
            setHardCap,
            setStartTime,
            setEndTime,
            setMinBuy,
            setMaxBuy,
            setTotalToken,
            setPresaleRate,
            setListingRate,
            creatPool,
            userTokenBal
            ,isPresaleError
            }}>
            {children}
        </ContextApi.Provider>
        
    )
}

export default ContextComponent;