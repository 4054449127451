import React, {useState} from 'react';
import Nav from '../partial/Nav';
import SecondNav from '../partial/SecondNav';
import WalNav from '../partial/WalNav';
import { Link } from 'react-router-dom';
import Footer from '../partial/Footer';

const Discover = ()=> {
    const dn = "none";
    const dop = "flex";
    const[op, setOp] = useState(dop);
    return(
        <>
       <div className="d-flex darkil">
       <Nav />

<section className="home_contents">
    <WalNav/>
    <div style={{padding:'60px 0px'}} className=" ">
         <h2 style={{fontWeight:'bold',textAlign:'center' , fontSize:'40px'}}>documentation & Q&A</h2>
      
      <img style={{width:'100%' , objectFit:'fill', height:'300px'}} src="images/logo.png" alt="brand banner"/>
    </div>



    <div style={{width:'100%', justifyContent:'space-between', padding:'80px 0px', display:'flex'}} className="d-flex px-4 box_wrapper_ justify-between">
         <div style={{boxShadow:'4px 4px 10px white', borderRadius:'20px', padding:'8px'}} className="box_">
              <h2 style={{textAlign:'center'}}>Presale docs</h2>
              <hr/>
              <p style={{fontWeight:'bold'}}> want to create a presale?</p>
              <small>
                if you're visiting with your mobile phone please click on top left 3 lines and then click on launchpad and navigate to create presale or simply click here <a href="/presale">Create Presale</a> and follow the instruction 
              </small>
              <div style={{height:'10px'}}></div>
              <small >
                if you're visiting with your laptop please see at the left side bar click on launchpad and navigate to create presale or simply click here <a href="/viewpools">View Pools</a> and start process by following instruction
              </small>

              <p  style={{fontWeight:'bold', margin:'15px 0px'}}> want to buy tokens and see presale listed?</p>
              <small>
                if you're visiting from mobile phone please click on top left 3 lines and click on launchpad and navigate to view pools and select a pool that are live and then navigate to view pools and buy token from there
              </small>
              <div style={{height:'10px'}}></div>
              <small >
                if you're visiting with your laptop please see at the left side bar click on launchpad and navigate to view pools and select a live pool and navigate view pools and buy from there
              </small>
              
         </div>
         <div style={{boxShadow:'4px 4px 10px white', borderRadius:'20px', padding:'8px'}} className="box_">
            <h2 style={{textAlign:'center'}}>Presale Q&A</h2>
            <hr/>
            <p style={{fontWeight:'bold', margin:'15px 0px'}}>what the charge for create a presale?</p> 
            <small>we are cheap in creating your presale currently our fee is 2 BNB to create a presale</small>
            <div style={{height:'10px'}}></div>
            <p style={{fontWeight:'bold', margin:'15px 0px'}}>want to use Presale Factory smart contract of Presale-Planet?</p> 
             
          <a href="https://bscscan.com/address/0x56dF924AFc2Fdbb613b65686780Cf4c98A503D29">
          https://bscscan.com/address/0x56dF9...
          </a>

         </div>
    </div>



    <div style={{width:'100%',  justifyContent:'space-between', padding:'80px 0px', display:'flex'}} className="d-flex px-4 box_wrapper_ justify-between">
         <div style={{boxShadow:'4px 4px 10px white', borderRadius:'20px', padding:'8px'}} className="box_">
              <h2 style={{textAlign:'center'}}> Token Creation docs</h2>
              <hr/>
              <p style={{fontWeight:'bold'}}> want to create a Token?</p>
              <small>
                if you're visiting with your mobile phone please click on top left 3 lines and then click on ToolBox and navigate to Token Builder and  select your token type by default it standard token we allowed you to create 3 types of tokens standard token buyback Baby token and liquidity generator token
              </small>
              <div style={{height:'10px'}}></div>
              <small >
                if you're visiting with your laptop please see at the left side bar click on ToolBox and navigate to Token Builder and  select your token type by default it standard token we allowed you to create 3 types of tokens standard token buyback Baby token and liquidity generator token
              </small>

            
              
         </div>
         <div style={{boxShadow:'4px 4px 10px white', borderRadius:'20px', padding:'8px'}} className="box_">
            <h2 style={{textAlign:'center'}}>Token Creation Q&A</h2>
            <hr/>
            <p style={{fontWeight:'bold', margin:'15px 0px'}}> What the price or fee to create a token?</p> 
            <small>Presale-planet is only platform where you can get you token for only 0.2 BNB create your token now <a href="/tokenbuilder">create token</a></small>
            <div style={{height:'10px'}}></div>
            <p style={{fontWeight:'bold', margin:'15px 0px'}}>want to use Token Creation Factory smart contract of Presale-Planet?</p> 
             
             <small>standard token Factory</small><br/>
          <a href="https://bscscan.com/address/0x84a19814cFeF9ee7c1eAd5e0A303D80763d3EAE2">
          https://bscscan.com/address/0x84a1...
          </a>
          <br/>
          <small>Liquidity Generator token Factory</small><br/>
          <a href="https://bscscan.com/address/0x68e794950427eB8B4749584555C998FC52c89d13">
          https://bscscan.com/address/0x68e
          </a>
          <br/>
          <small>BuyBack Baby  token Factory</small><br/>
          <a href="https://bscscan.com/address/0x35A7aeBC571Ba230bBE53918E9A2DdFd225b913F">
          https://bscscan.com/address/0x35A
          </a>
          
         </div>
    </div>




      <div style={{width:'100%',  justifyContent:'space-between', padding:'80px 0px', display:'flex'}} className="d-flex px-4 box_wrapper_ justify-between">
         <div style={{boxShadow:'4px 4px 10px white', borderRadius:'20px', padding:'8px'}} className="box_">
              <h2 style={{textAlign:'center'}}> Token Lock docs</h2>
              <hr/>
              <p style={{fontWeight:'bold'}}> want to Lock a Token?</p>
              <small>
              If you're visiting with your mobile phone click on top left 3 lines and click on locker and navigate to Create Lock or simply click here <a href="/locker">Lock your token</a> and follow the instruction
              </small>
              <div style={{height:'10px'}}></div>
              <small >
                if you're visiting with your laptop please see at the left side bar click on Locker and navigate to Create Lock or simply click here <a href="/locker">Lock your token</a> and follow the instruction
              </small>

            
              
         </div>
         <div style={{boxShadow:'4px 4px 10px white', borderRadius:'20px', padding:'8px'}} className="box_">
            <h2 style={{textAlign:'center'}}>Token Lock Q&A</h2>
            <hr/>
            <p style={{fontWeight:'bold', margin:'15px 0px'}}>What is the purpose of a token lock??</p> 
            <small>A token lock secures tokens by restricting their transfer for a predetermined period, ensuring stability, preventing premature selling, and protecting against market volatility and potential scams.. </small>
            <div style={{height:'10px'}}></div>
           
            <p style={{fontWeight:'bold', margin:'15px 0px'}}>Does Presale-planet charge a fee to lock tokens?</p> 
            <small>At Presale-planet, we offer competitive and transparent pricing for our token lock services. While some fees may apply, we ensure they are minimal and clearly outlined to provide you with the best value and security for your token locking needs. <a href="/locker">Lock your Token</a></small>
            <div style={{height:'10px'}}></div>
           
           
           
            <p style={{fontWeight:'bold', margin:'15px 0px'}}>want to use Token Lock Factory smart contract of Presale-Planet?</p> 
             
             <small>Token Lock Factory</small><br/>
          <a href="https://bscscan.com/address/0xe9D3BA67B004E4EB7aAAE1da63E52dDa7f7aB567">
          https://bscscan.com/address/0xe9D
          </a>
         
         </div>
    </div>


<Footer/>

</section>



</div>
        </>
    )
}

export default Discover;