import React, {useContext} from 'react';
import { ContextApi } from '../../../utilities';

const PreFive = ({pdn4, pBtn4})=>{

  const {getToken,creatPool,
    softCap,
    hardCap,
    startTime,
    endTime,
    minBuy,
    maxBuy,
    userTokenBal,
    totalToken,
    presaleRate,
    tokenDecimals, description, tokenName, tokenSymbol, tokenSupply} = useContext(ContextApi);
     
   
    return(
        <>
         <div className="col-12 col-md-12 col-lg-8 col-xl-8  rightPresale" style={{display:pdn4}}>
         
         <div className="dataWrap">
            <span>Token address</span>
            <span>{getToken}</span>
          </div>

          <div className="dataWrap">
            <span>Token Name</span>
            <span>{tokenName}</span>
          </div>

          <div className="dataWrap">
            <span>Token Symbol</span>
            <span>{tokenSymbol}</span>
          </div>
           
         
          <div className="dataWrap">
            <span>token Supply</span>
            <span>{tokenSupply}</span>
          </div>

          <div className="dataWrap">
            <span>Token Decimals</span>
            <span>{tokenDecimals}</span>
          </div> 
          
         

          <div className="dataWrap">
            <span>Soft Cap</span>
            <span>{softCap}</span>
          </div>

          <div className="dataWrap">
            <span>Hard Cap</span>
            <span>{hardCap}</span>
          </div>

          <div className="dataWrap">
            <span>Min Buy</span>
            <span>{minBuy}</span>
          </div>
          <div className="dataWrap">
            <span>Max Buy</span>
            <span>{maxBuy}</span>
          </div>


          <div className="dataWrap">
            <span>Start time</span>
            <span>{startTime}</span>
          </div>


          <div className="dataWrap">
            <span>End time</span>
            <span>{endTime}</span>
          </div>

         
         
          <div style={{height:'auto', flexDirection:'column'}} className="dataWrap">
          
            <textarea value={description} style={{textAlign:'left',height:'200px',color:'white',  border:'none', outline:'none', background:'transparent'}}>{description}</textarea>
          </div>
   {
    userTokenBal < 0 ? <p style={{color:'red'}}>you have zero {tokenSymbol} Token you must have this {hardCap * presaleRate} amount of {tokenSymbol} </p>: userTokenBal <=  hardCap * presaleRate?<p style={{color:'red'}}>you don't have enough {tokenSymbol} Balance to create you must have this {hardCap * presaleRate} amount of {tokenSymbol}</p>:''
   }
{
  userTokenBal != 0 ?   <button onClick={creatPool} className="nextBtn" >
  Create 
 </button>: userTokenBal <= 0 ? <button disabled style={{background:'gray'}} className="nextBtn" >
  Create 
</button>:userTokenBal <=  hardCap * presaleRate?<button disabled style={{background:'gray'}} className="nextBtn" >
  Create 
</button>:''
  
  

}

    </div>
        </>
    )
}

export default PreFive;