import React, {useContext} from 'react';
import {Link } from 'react-router-dom';
import { ContextApi } from '../utilities';
import SlideImg from './partial/SlideImg';
import Nav from './partial/Nav';
import SecondNav from './partial/SecondNav';
import WalNav from './partial/WalNav';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Footer from './partial/Footer';
const Home = ({darkLight, darki})=>{
  const {presaleDetails, setTakeFeeAddress} = useContext(ContextApi);
  const moData = presaleDetails?.slice(-3)

    return (
        <>
       <div className="d-flex darkil">
       <Nav darkLight={darkLight}/>

<section className="home_contents">
    <WalNav darkLight={darkLight} darki={darki}/>
   <div className="container mx-auto">
    <SecondNav/>
            <div style={{padding:'25px'}} className="homeTitleWrap">
              {/* <div className="homeTititleWrapFirstChild">
                <img src="images/favico.png" alt="favico"/>
              </div> */}
              <div className="homeTitleWrapSecondChild">
              <h1 className="hhome">
              Innovating the crypto ecosystem through the AIO DeFi Launchpad
                </h1>
                 <p>
                 By virtue of its efficient and user-friendly interface, TaskObey enables projects to create tokens and presales in a matter of seconds.
                  </p>
              </div>

              <div className="homeTitleWrapThirdChild">
                <Link to="/presale">Create</Link>
              </div>
            </div>
<div>
    
<div className="home_second_wrap  row">

<div className="col-12 col-md-4 col-lg-4 col-xl-4 row">
<div className="col-11 col-md-11 col-lg-11 col-xl-11  home_s_box m-auto">
<h5 style={{marginBottom:"10px"}} className="icond">  
<i className="fa-solid fa-rocket"  style={{marginRight:"5px",marginTop:'5px'}}></i> Latest Pools</h5>
<div style={{width:'100%', height:'0.1px', background:'white'}} ></div>
{/* <Link to="/detail" className="homeLinki "> 
<div className="subWrap">
<img src="images/cake.png" alt="token logo"/>
<div className="swrapb">
<p>PancakeSwap <br/><span style={{color:'grey'}}>CAKE</span></p>

</div>
<div className="nbl" style={{padding:'2px 7px',borderRadius:"10px", background:"rgb(255 238 41 / 52%)", fontWeight:'bold'}}>
BSC
</div>
</div>
</Link> */}

{  moData && moData.map((pool, indx)=>(
  <Link key={indx} to={`/detail?token=${pool.PresaleAddress}&&owner=${pool.owner}`} className="homeLinki "> 
<div className="subWrap">
<img src={pool.logo} alt="token logo"/>
<div className="swrapb">
<p>{pool.tokenName} <br/><span style={{color:'grey'}}>{pool.tokenSymbol}</span></p>

</div>

</div>
</Link>
))
}


</div>
</div>

<div className="col-12 col-md-4 col-lg-4 col-xl-4 row">
<div className="col-11 col-md-11 col-lg-11 col-xl-11  home_s_box m-auto">
<h5 style={{marginBottom:"10px"}}  className="icond">  <i className="fa-solid fa-fire-flame-curved" ></i>  Hot Contest</h5>
<div style={{width:'100%', height:'0.1px', background:'white'}} ></div>

{  moData && moData.map((pool, indx)=>(
  <Link key={indx} to={`/detail?token=${pool.PresaleAddress}&&owner=${pool.owner}`} className="homeLinki "> 
<div className="subWrap">
<img src={pool.logo} alt="token logo"/>
<div className="swrapb">
<p>{pool.tokenName}<br/><span style={{color:'grey'}}>{pool.tokenSymbol}</span></p>

</div>

</div>
</Link>
))
}

</div>
</div>
<div className="col-12 col-md-4 col-lg-4 col-xl-4 row">
<div className="col-11 col-md-11 col-lg-11 col-xl-11  home_s_box m-auto">
    
<h5 style={{marginBottom:"10px"}}  className="icond"> <i className="fa-solid fa-database"></i>  New Tokens</h5>
<div style={{width:'100%', height:'0.1px', background:'white'}} ></div>

{  moData && moData.map((pool, indx)=>(
  <Link key={indx} to={`/detail?token=${pool.PresaleAddress}&&owner=${pool.owner}`} className="homeLinki "> 
<div className="subWrap">
<img src={pool.logo} alt="token logo"/>
<div className="swrapb">
<p>{pool.tokenName} <br/><span style={{color:'grey'}}>{pool.tokenSymbol}</span></p>


</div>

</div>
</Link>
))
}




</div>
</div>



</div>

</div>




  {/* <div className="home_contributaion">
                
  <div id="contr01" className="contribution_wrap">
                    <div className="contribution_icon">
                    <i className="fa-sharp fa-solid fa-share-nodes"></i>
                    </div>
                    <div className='contribution_content'>
                      <h4>Projects <span className="pdot">..........</span></h4>
                      <h3>{presaleDetails?.lenth}+</h3>
                    </div>
                </div>

                <div className="contribution_wrap">
                    <div className="contribution_icon">
                    <i className="fa-solid fa-users"></i>
                    </div>
                    <div className='contribution_content'>
                    <h4>Total Participate</h4>
                    <h3>{presaleDetails?.lenth}+</h3>
                     
                    </div>
                </div>

                <div className="contribution_wrap">
                    <div className="contribution_icon">
                    <i className="fa-solid fa-hand-holding-dollar"></i>
                    </div>
                    <div className='contribution_content'>
                      <h4>Total funding</h4>
                      <h3>{presaleDetails?.lenth}+k</h3>
                    </div>
                </div>
               


              
           </div> */}




       <div className="container mx-auto " style={{margin:'150px 0px'}}>
        <h2 style={{textAlign:'center'}}>FAQ's</h2>
       <MDBContainer className="mt-5" style={{maxWidth: '1000px'}}>
      <MDBAccordion  >
        <MDBAccordionItem collapseId={1} headerStyle={{color:'white'}} headerTitle="What is an Initial DEX Offering (IDO)?">
   <p style={{color:'white'}}>
   An Initial DEX Offering (IDO) is a fundraising method in the cryptocurrency space where a project launches its token on a decentralized exchange (DEX). Unlike traditional Initial Coin Offerings (ICOs), an IDO allows immediate liquidity and trading of the token on the DEX upon launch. This approach leverages the decentralized nature of DEXs, providing enhanced transparency, accessibility, and security for investors and project developers.
   </p>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle="How can I participate in the IDO?">
            <p style={{color:'white'}}>
            To participate in our IDO, follow these steps:

Prepare a Wallet: Ensure you have a compatible cryptocurrency wallet (e.g., MetaMask) with sufficient funds in the required  BNB,
Connect to the DEX: Visit the specified decentralized exchange platform and connect your wallet.
Buy Tokens: At the IDO launch time, navigate to the token sale section, enter the amount you wish to purchase, and confirm the transaction.
Receive Tokens: Once the transaction is confirmed, the purchased tokens will be transferred to your wallet.
       
            </p>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle="What is a presale?">
          <p style={{color:'white'}}>
          A presale is an early phase of fundraising for a cryptocurrency project, where tokens are sold before the main public sale (IDO). During the presale, tokens are often offered at a discounted price to early investors. This phase helps the project raise initial funds for development, marketing, and other essential activities. Participating in a presale can provide investors with the opportunity to acquire tokens at a lower price before they become available to the general public.
          </p>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={4} headerTitle="What are the risks associated with IDOs?">
           <p style={{color:'white'}}>
           Market Volatility: Cryptocurrencies are highly volatile, and token prices can fluctuate significantly.
    Project Risk: There is always a risk that the project may not deliver on its promises or could fail.
    Security Concerns: Ensure you use secure wallets and platforms to avoid scams and hacks.
    Regulatory Risks: The regulatory environment for cryptocurrencies varies by region and can impact the project's operations and your investment.

We recommend conducting thorough research and understanding the project's details before participating in any IDO.
           </p>
        </MDBAccordionItem>
      </MDBAccordion>
    </MDBContainer>

       </div>





           <div className="slide_show">
    <span className="ptnr">Partners</span>
<marquee>
 
  <SlideImg/>

 
</marquee>
</div>





<p style={{textAlign:'center', fontSize:"12px",marginTop:'20px'}}>
Disclaimer: The content of any material provided or published by TaskObey does not represent, in any way, financial advice and is not an investment recommendation. We are not responsible and do not accept liability for any loss of assets to any person or persons acting upon information present in any material associated with TaskObey.
</p>

{/* 
<button onClick={()=>setTakeFeeAddress()} style={{background:'red'}}>Set Fee of Contracts</button> */}
<Footer/>


     </div>


</section>

       </div>
         
      
        </>
    )
}


export default Home;