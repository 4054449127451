

import React , {useContext} from 'react';
import { ContextApi } from '../../../utilities';
import ConnectButton from '../../../walletBtn';

const PreThirdPage = ({pdn1, pBtn1})=>{
  
  const {
    isCo,
    whitelist,
    tokenSymbol,
    totalToken,
    tokenName,
    softCap,
    hardCap,
    startTime,
    endTime,
    minBuy,
    maxBuy,
  
    setWhitelist,
    presaleRate,
    setSoftCap,
    setHardCap,
    setStartTime,
    setEndTime,
    setMinBuy,
    setMaxBuy,
    setPresaleRate,

  } = useContext(ContextApi);



    return(
        <>
              <div className="col-12 col-md-12 col-lg-8 col-xl-8  rightPresale" style={{display:pdn1}}>
          



              <div className="row">
              <div className="col-12">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Presale Rate <span style={{color:"red"}}>*</span></label>
          <input required value={presaleRate} onChange={(e)=> setPresaleRate(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder=" 1000"/>
        <span style={{color:'lightblue'}}>
        1 BNB = ? {tokenName } {' '}
        If I spend 1 BNB on how many tokens will I receive?
        </span>
      
        </div>
        

        
      </form>


              </div>

              {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <p>.</p>

              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Total Token <span style={{color:"red"}}>*</span></label>
          <input value={listingRate} onChange={(e)=> setListingRate(e.target.value)}  type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="24700"/>
          <span style={{color:'lightblue'}}>
        Total {tokenName } {' '} Want to sell
      
        </span>
       
        </div>
      


        
      </form>


              </div>
              
               */}
              </div>


              <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Softcap BNB <span style={{color:"red"}}>*</span></label>
          <input value={softCap} onChange={(e)=> setSoftCap(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder=" 0.2 BNB"/>
        </div>
        

        
      </form>


              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <p > .</p>
                {
                  softCap > hardCap? <p style={{color:'red'}}>Soft Cap should less then hardCap</p>:
                  softCap >= (0.25*hardCap) ? '':
                  <p style={{color:'red'}}>Soft Cap must should have 25% or equal of hard cap</p>
                }

              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Hardcap BNB <span style={{color:"red"}}>*</span></label>
          <input value={hardCap} onChange={(e)=> setHardCap(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder=" 3 BNB"/>
        </div>
      


        
      </form>


              </div>
              
              
              
              
              </div>

              <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Min Buy <span style={{color:"red"}}>*</span></label>
          <input value={minBuy} onChange={(e)=> setMinBuy(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="  0.2 BNB"/>
        </div>
          </form>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <p>.</p>

              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Max Buy <span style={{color:"red"}}>*</span></label>
          <input style={{ WebkitAppearance: 'none' }}  value={maxBuy} onChange={(e)=> setMaxBuy(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="  3 BNB"/>
        </div>
        </form>


              </div>
              </div>

              <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <p>.</p>
              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">Start Time <span style={{color:"red"}}>*</span></label>
          <input value={startTime} onChange={(e)=> setStartTime(e.target.value)} type="datetime-local" className="form-control preinput" id="exampleInputEmail1" />
        </div>
          </form>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <p>.</p>

              <form>
        <div className="form-group">
          <label for="exampleInputEmail1">END Time <span style={{color:"red"}}>*</span></label>
          <input value={endTime} onChange={(e)=> setEndTime(e.target.value)} type="datetime-local" className="form-control preinput"    />
        </div>
        </form>


              </div>
              </div>


              {/* <form>
        <div className="form-group  mt-4">
          <label for="exampleInputEmail1">Vesting period each cycle<span style={{color:"red"}}>*</span></label>
          <input type="text" className="form-control preinput"   placeholder="  023 BNB"/>
        </div>
          </form>

          <form>
        <div className="form-group  mt-4">
          <label for="exampleInputEmail1">Unlock KYC <span style={{color:"red"}}>*</span></label>
          <input type="text" className="form-control preinput"   placeholder=" Ex:"/>
        </div>
          </form> */}

{/* 
          <form>
        <div className="form-group  mt-4">
          <label for="exampleInputEmail1">Unlock Audit<span style={{color:"red"}}>*</span></label>
          <input type="text" className="form-control preinput"   placeholder="EX: "/>
        </div>
          </form> */}

        <div className="fo mt-2">
        <input checked={whitelist} onChange={(e)=>setWhitelist(e.target.checked)} type="radio" /> Use WhiteList
        <p>You can enable/disable whitelist anytime.</p>
        </div>

      {
        hardCap != '' && presaleRate != ''? <p style={{fontSize:'17px', margin:'10px 0px', color:'skyblue'}}>
        you have to spend this {hardCap*presaleRate} amount of {tokenSymbol} token
      </p>:''
      }

            {
              isCo() == null? <>
              {
                presaleRate !=='' &&  endTime !== '' && startTime !== ''&&
                maxBuy !== '' && minBuy !== '' && hardCap !== '' && softCap !== ''?
                <button onClick={()=> pBtn1()} className="nextBtn" >
                Next
               </button>
                :<button disabled style={{background:'gray'}} className="nextBtn" >
                Next
            </button>
           
              }
                
              </>:<ConnectButton/>
            }
          </div>
    
    

        </>
    )
}

export default PreThirdPage;