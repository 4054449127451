import React, { useContext} from 'react';
import {ContextApi} from '../../../utilities.js'

const Standard = ()=>{
   
     const {
          createStandard,
          isCo,
          standardName, setStandardName,
          standardSymbol, setStandardSymbol,
          standardDecimals, setStandardDecimals,
          standardSupply, setStandardSupply,
     } = useContext(ContextApi);

    const crateToken =()=>{
        alert ("only completed UI here is don't have any backend or solidty smart contract Work")
    }
    return(
        <>
       
          <div className="col-12 col-md-8  rightPresale" >
    
    <div className="row">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
      <form>
 <div className="form-group">
 <label for="exampleInputEmail1">Name <span style={{color:"red"}}>*</span></label>

 <input onChange={(e)=>setStandardName(e.target.value)} value={standardName} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>
 </form>
      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
      <form>
 <div className="form-group">
 <label for="exampleInputEmail1">Symbol <span style={{color:"red"}}>*</span></label>
 <input value={standardSymbol} onChange={(e)=>setStandardSymbol(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex : "/>
 </div>
 </form>
 
 
      </div>
     </div>
    
     <div className="row mt-3">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
      <form>
 <div className="form-group">
 <label for="exampleInputEmail1">Decimals <span style={{color:"red"}}>*</span></label>
 <input onChange={(e)=>setStandardDecimals(e.target.value)} value={standardDecimals} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>
 </form>
      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
      <form>
 <div className="form-group">
 <label for="exampleInputEmail1">Total Supply <span style={{color:"red"}}>*</span></label>
 <input onChange={(e)=>setStandardSupply(e.target.value)} value={standardSupply} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex : "/>
 </div>
 </form>
 
 
      </div>
     </div>
 
 
  {
     isCo() != null ? isCo():
     <button className="nextBtn" onClick={createStandard} style={{width:'128px'}}>Create Token</button>
 
  }
 
 </div>
        </>
    )
}

export default Standard;