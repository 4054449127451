import React, {useContext,useEffect, useState} from 'react';
import Nav from '../partial/Nav';
import WalNav from '../partial/WalNav';
import { ContextApi } from '../../utilities';
import {ethers} from 'ethers';
import { useAccount } from 'wagmi';
import PresaleFactoryAbi from '../../abis/PresaleFactory.json'
const Locker = ()=> {
    const {address} = useAccount()
 const {
    tokenLockDate, setTokenLockDate,
    ltokenAmount, setLtokenAmount,
    tokenTitle, setTokenTitle,
    ltoken, setLtoken,
    isCo,
    createLock
 } = useContext(ContextApi)
 const PresaleFactoryABI = PresaleFactoryAbi.abi;
 const PresaleFactoryAddress = '0x56dF924AFc2Fdbb613b65686780Cf4c98A503D29'



 const [tokenName, setTokenName] = useState('')
 const [tokenSymbol, setTokenSymbol]  = useState('');
 const [tokenDecimals, setTokenDecimals] = useState('')
 const [tokenSupply, setTokenSupply] = useState('')
 const [abistatus, setAbistatus] = useState(false);

 const getTokenInfo = async () => {
    try {
        const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
        const contract = new ethers.Contract(PresaleFactoryAddress, PresaleFactoryABI, provider);

        const result = await contract.tokenInfo(ltoken, address?address:'0xe4e7a312CCDd664CC19DCfD5b1f3af5b5c81D3ce');
        
        if (result) {
            setTokenName(result[1]);
            setTokenSymbol(result[2]);
            const totalSupplyInEther = ethers.utils.formatEther(result[0]);
            const decimalInEther = result[3];
            const userBal = ethers.utils.formatEther(result[4]);
            setTokenDecimals(decimalInEther.toString());
            // setToekBalance(userBal.toString())
            setTokenSupply(totalSupplyInEther.toString());
            setAbistatus(true);
        }
    } catch (error) {
        console.error('Error retrieving token info:', error);
       
    }
};

useEffect(() => {
    getTokenInfo();
}, [ltoken]);




    return(
        <>

<div className="d-flex">
       <Nav/>

<section className="home_contents">

    <WalNav/>
   <div className="container presaleWrap mx-auto row">
   <div className="col-12 col-md-11  rightPresale" >
    
    <div className="">
<div className="lockInputFild">

<form>
<div className="fo">
<label for="exampleInputEmail1"> Token address<span style={{color:"red"}}>*</span></label>
<input value={ltoken} onChange={(e)=> setLtoken(e.target.value)} type="text"  className="form-control preinput"   placeholder="Token or LP Token address"/>

</div>
</form>

{
  abistatus == true?
  <div style={{marginTop:'20px',width:'100%',flexDirection:'column', justifyContent:'space-between',display:'flex'}} className="w-full flex justify-between">
  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full justify-between">
    <span>Token Name</span> <span>{tokenName}</span>
  </div>

  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full my-2 justify-between">
    <span>Token Symbol</span> <span>{tokenSymbol}</span>
  </div>
  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full my-2 justify-between">
    <span>Token Decimals</span> <span>{tokenDecimals}</span>
  </div>

  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full my-2 justify-between">
    <span>Total Supply</span> <span>{tokenSupply}</span>
  </div>



</div>:''
 }
</div>

</div>
    
 
<form>
<div className="fo mt-2">
<label for="exampleInputEmail1">Title<span style={{color:"red"}}>*</span></label>
<input value={tokenTitle} onChange={(e)=> setTokenTitle(e.target.value)} type="text"  className="form-control preinput"   placeholder="Ex : My Lock"/>

</div>
</form>
<form>
<div className="fo mt-2">
<label for="exampleInputEmail1">Amount<span style={{color:"red"}}>*</span></label>
<input value={ltokenAmount} onChange={(e)=>setLtokenAmount(e.target.value)} type="number"  className="form-control preinput"   placeholder="0"/>


 
</div>
</form>

<form>
<div className="fo mt-2">
<label for="exampleInputEmail1">{"Lock until (UTC time)"}<span style={{color:"red"}}>*</span></label>
<input value={tokenLockDate} onChange={(e)=> setTokenLockDate(e.target.value)} type="datetime-local"  className="form-control preinput"   />


 
</div>
</form>



{
    isCo() == null?<button onClick={createLock} className="nextBtn" style={{fontSize:'13px', width:'152px', padding:'7px 0px'}}>Approve & Lock</button>
:isCo()
}

</div>

    </div>

    </section>

    </div>
        
        </>
    )
}


export default Locker;