


import React from 'react';


const SlideImg = ()=>{
    return(
        <>

        <img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>

        <img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/><img style={{height:"60px", margin:"0px 20px"}} src="images/s1.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s2.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s3.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s4.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s5.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s6.png"alt="s1"/>
        <img style={{height:"60px", margin:"0px 20px"}} src="images/s7.png"alt="s1"/>

        
        </>
    )
}

export default SlideImg;