import React, {useEffect, useState, useContext} from 'react';
import Nav from '../Nav';
import WalNav from '../WalNav';
import PresaleFactoryAbi from '../../../abis/PresaleFactory.json'
import { ethers } from 'ethers';

const Innerinfo = ()=> {
    const PresaleFactoryAddress = '0x56dF924AFc2Fdbb613b65686780Cf4c98A503D29'
    const preABI = PresaleFactoryAbi.abi

    const [token, setToken] = useState(null);
    const [tokenTitle, setLockTitle] = useState(null)
    const [tokenAmount, setTokenAmount] = useState(null)
    const [bgNumber, setBgNumber]  = useState(null)
    const [ginfo, setgInfo] = useState([])
 console.log(ginfo, 'token')
    useEffect(()=>{
        const url  = window.location.href;
        const ur = new URLSearchParams(url);
        setLockTitle(ur.get("tokenTitle"));
        setToken(ur.get('tokenAddress'));
        setTokenAmount(ur.get("tokenAmount"))
       
    },[])

    useEffect(()=>{
        const getTokenInfo = async()=>{
            try{
                const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org')
                 const contract = new ethers.Contract(PresaleFactoryAddress, preABI, provider);
               const tokenInfo = await contract.tokenInfo(token, `0x56dF924AFc2Fdbb613b65686780Cf4c98A503D29`)
               setgInfo(tokenInfo)
               setBgNumber( ethers.BigNumber.from(tokenInfo[3]))
            }catch(error){
                console.log(error)
            }
        }
        getTokenInfo()
    }, [token])


    return(
        <>
            <div className="d-flex">
       <Nav/>

<section className="home_contents">
  
    <WalNav/>
   <div className="container mt-5 mx-auto ">

          <div className="lockTableInfo">
          <div className="lockTabelcell">
                <span>
                Lock Title</span>

                    <span>
                   {tokenTitle}
                    </span>
            </div>
            <div className="lockTabelcell">
      

                <span>
                Current Locked Amount</span>

                <span>
                 {tokenAmount}
                </span>
            </div>
          
            <div className="lockTabelcell">
                <span>
                Token Address</span>

                <span>
                {token}
                </span>
            </div>


            <div className="lockTabelcell">
                <span>
                Token Name</span>

                <span>
                {ginfo[1]}
                </span>
            </div>

            <div className="lockTabelcell">
                <span>
                Token Symbol</span>

                <span>
                 {ginfo[2]}
                </span>
            </div>

            <div className="lockTabelcell">
                <span>
                Token Decimals</span>

                {
                    bgNumber ? <span>{ bgNumber?.toString()}</span>:'loading..'
                }
            </div>







          </div>
      </div>
      </section>

      </div>
        </>
    )
}

export default Innerinfo;