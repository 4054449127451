

import React, {useContext, useState} from 'react';
import { ContextApi } from '../../../utilities';
import ConnectButton from '../../../walletBtn';

const PreFourthPage = ({pdn3, pBtn3})=>{
 
     const {
          isCo,
          logoUrl1, setLogoUrl1,
          bannerUrl1, setBannerUrl1,
          website1, setWebsite1,
          promoVid1, setPromoVid1,
          discord1, setDiscord1,
          whitepaper1, setWhitePaper1,
          facebook1, SetFaceBook1,
          twitter1, setTwitter1,
          github1,setGithub1,
          telegram1,  setTelegram1,
          description1, setDescription1

     } = useContext(ContextApi);

    


    return(
        <>
        <div className="col-12 col-md-12 col-lg-8 col-xl-8  rightPresale" style={{display:pdn3}}>
     
     <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Logo URL <span style={{color:"red"}}>*</span></label>
<input type="text" value={logoUrl1} onChange={(e)=>setLogoUrl1(e.target.value)} className="form-control preinput" id="exampleInputEmail1"   placeholder="https://website1.com/logo.png"/>

</div>



</form>


     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
   

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Banner URL <span style={{color:"red"}}>*</span></label>
<input value={bannerUrl1} onChange={(e)=>setBannerUrl1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="https://website1.com/banner.png"/>

</div>




</form>


     </div>
    
    
    
    
    </div>



    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Website<span style={{color:"red"}}>*</span></label>
<input value={website1} onChange={(e)=>setWebsite1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="https:website1.com"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Promo Video <span style={{color:"red"}}>*</span></label>
<input value={promoVid1} onChange={(e)=>setPromoVid1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="https://youtu.be/ahobeirZzp.."/>
</div>
</form>


     </div>
    </div>



    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Discord1<span style={{color:"red"}}>*</span></label>
<input value={discord1} onChange={(e)=>setDiscord1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">whitepaper1 <span style={{color:"red"}}>*</span></label>
<input value={whitepaper1} onChange={(e)=>setWhitePaper1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>


     </div>
    </div>





    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Facebook1<span style={{color:"red"}}>*</span></label>
<input value={facebook1} onChange={(e)=>SetFaceBook1(e.target.value)} type="text"  className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Twitter1 <span style={{color:"red"}}>*</span></label>
<input value={twitter1} onChange={(e)=>setTwitter1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>


     </div>
    </div>





    <div className="row">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
    
     <form>
<div className="form-group">
<label for="exampleInputEmail1">Github1<span style={{color:"red"}}>*</span></label>
<input value={github1} onChange={(e)=>setGithub1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>
     </div>

     <div className="col-12 col-md-6 col-lg-6 col-xl-6">

     <form>
<div className="form-group">
<label for="exampleInputEmail1">Telegram1 <span style={{color:"red"}}>*</span></label>
<input value={telegram1} onChange={(e)=>setTelegram1(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
</div>
</form>


     </div>
    </div>




    <form>
<div className="form-group">
<label for="exampleInputEmail1">Description1 <span style={{color:"red"}}>*</span></label>
<textarea value={description1} onChange={(e)=>setDescription1(e.target.value)} style={{height:"150px"}} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"></textarea>
</div>
</form>
    
{
     description1.length < 150? <p style={{color:'red'}}>description1 charecter should be 150 minimum</p>:''
}
    

   
{
     isCo() == null ?  <>
     {
          logoUrl1 != '' && description1 != '' && description1.length >= 150 && website1 != '' && bannerUrl1 != '' ?
          <button onClick={()=> pBtn3()} className="nextBtn" >
     Next
  </button>:<button disabled style={{background:'gray'}} className="nextBtn" >
     Next
  </button>
     }
     </>:<div style={{padding:'10px 0px'}}><ConnectButton/></div>
}

</div>



    
        </>
    )
}

export default PreFourthPage;