import React, {useEffect, useState} from 'react';
import WalNav from '../partial/WalNav';
import Nav from '../partial/Nav';
import SecondNav from '../partial/SecondNav';
import { useAccount } from 'wagmi';
const Learn = ()=>{

 const {address} = useAccount()
 const [transaction, setTransaction] = useState('')

  useEffect(()=>{
      const url = window.location.href;
      const ur = new URL(url)
      const newUrl = new URLSearchParams(ur.search)
    setTransaction(newUrl.get('hash'));
  },[])

    return(<>
      
      <div className="d-flex">
       <Nav/>

<section className="home_contents">
    <WalNav/>
   <div style={{display:'flex', flexDirection:'column', height:'500px', width:'100%', alignItems:'center', justifyContent:'center'}} className="container mx-auto">
    
   <a blank="_blank" style={{padding:'8px 20px',textDecoration:'none', background:'#0d64cf', fontWeight:'bold', color:'white ', borderRadius:'30px', marginBottom:'30px'}} href={`https://bscscan.com/tx/${transaction}`}>get your token Info</a>
    
   <a blank="_blank" style={{padding:'8px 20px',textAlign:'center',textDecoration:'none', background:'#0d64cf', fontWeight:'bold', color:'white ', borderRadius:'30px'}} href={`https://bscscan.com/address/${address}`}>or get token address from your last transaction</a>
  
  </div>
  </section>
 </div>
    
    </>)
}

export default Learn