import React, {useContext} from 'react';
import { ContextApi } from '../../../utilities';
const PreFive = ({pdn4, pBtn4})=>{

  const {fToken,createFairlaunch,
    fsoftCap,
    fbuyback,
    fPresaleStart,
    fPresaleEnd,
    sellingAmount,
    liquidityPercentage
    ,tokenDecimals1, description1, tokenName1, tokenSymbol1, tokenSupply1} = useContext(ContextApi);
     
   
    return(
        <>
         <div className="col-12 col-md-12 col-lg-8 col-xl-8  rightPresale" style={{display:pdn4}}>
         
         <div className="dataWrap">
            <span>Token address</span>
            <span>{fToken}</span>
          </div>

          <div className="dataWrap">
            <span>Token Name</span>
            <span>{tokenName1}</span>
          </div>

          <div className="dataWrap">
            <span>Token Symbol</span>
            <span>{tokenSymbol1}</span>
          </div>
           
         
          <div className="dataWrap">
            <span>token Supply</span>
            <span>{tokenSupply1}</span>
          </div>

          <div className="dataWrap">
            <span>Token Decimals</span>
            <span>{tokenDecimals1}</span>
          </div> 
          
         

          <div className="dataWrap">
            <span>Soft Cap</span>
            <span>{fsoftCap}</span>
          </div>

          <div className="dataWrap">
            <span>Buy Back Fee</span>
            <span>{fbuyback}</span>
          </div>

          <div className="dataWrap">
            <span>Token Selling Amount</span>
            <span>{sellingAmount}</span>
          </div>
          <div className="dataWrap">
            <span>liquidity parcentage</span>
            <span>{liquidityPercentage}</span>
          </div>


          <div className="dataWrap">
            <span>Start time</span>
            <span>{fPresaleStart}</span>
          </div>


          <div className="dataWrap">
            <span>End time</span>
            <span>{fPresaleEnd}</span>
          </div>

         
         
          <div style={{height:'auto', flexDirection:'column'}} className="dataWrap">
          
            <textarea value={description1} style={{textAlign:'left',height:'200px',color:'white',  border:'none', outline:'none', background:'transparent'}}>{description1}</textarea>
          </div>

 <button onClick={createFairlaunch} className="nextBtn" >
    Create 
 </button>


    </div>
        </>
    )
}

export default PreFive;