
import { useContext } from 'react';
import { ContextApi } from '../../../utilities';
 


const Buyba = ()=>{
    const {
     buyName , setBuyName,
     buySymbol, setBuySymbol,
     buySupply, setBuySupply,
     buyReward, setBuyReward,
     buyLiqFee, setBuyLiqFee,
     buyMarkFee, setBuyMarkFee,
     buyBuyFee, setBuyBuyFee,
     buyRefFee, setBuyRefFee,
     createBuyback,
     isCo
    } = useContext(ContextApi);
  
    return(
        <>
          <div className="col-12 col-md-8  rightPresale" >
    
    <div className="row">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
      
 <div className="form-group">
 <label for="exampleInputEmail1">Name <span style={{color:"red"}}>*</span></label>
 <input value={buyName} onChange={(e)=>setBuyName(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>

      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
    
 <div className="form-group">
 <label for="exampleInputEmail1">Symbol <span style={{color:"red"}}>*</span></label>
 <input value={buySymbol} onChange={(e)=>setBuySymbol(e.target.value)}  type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex : "/>
 </div>
 
 
 
      </div>
     </div>
    
     <div className="row mt-3">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
    
 <div className="form-group">
 <label for="exampleInputEmail1">Total Supply <span style={{color:"red"}}>*</span></label>
 <input value={buySupply} onChange={(e)=>setBuySupply(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>

      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
      <form>
 <div className="form-group">
 <label for="exampleInputEmail1">Router <span style={{color:"red"}}>*</span></label>
 <select className="form-control preinput" style={{border:'1px solid lightgrey'}}>
    <option>PancakeSwap</option>

 </select>
 </div>
 </form>
 
 
      </div>
     </div>



   

     <div className="row mt-3">
     <div className="col-12 ">
     
      
 <div className="form-group">
 <label for="exampleInputEmail1">Reward token<span style={{color:"red"}}>*</span></label>
 <input value={buyReward} onChange={(e)=>setBuyReward(e.target.value)} type="text" className="form-control preinput" id="exampleInputEmail1"  placeholder="0-NaN"/>
 </div>

      </div>
 </div>


 
 <div className="row mt-3">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
      
 <div className="form-group">
 <label for="exampleInputEmail1">Liquidity Fee (%) <span style={{color:"red"}}>*</span></label>
 <input value={buyLiqFee} onChange={(e)=>setBuyLiqFee(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>

      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
    
 <div className="form-group">
 <label for="exampleInputEmail1">Buyback Fee (%) <span style={{color:"red"}}>*</span></label>
 <input value={buyBuyFee} onChange={(e)=>setBuyBuyFee(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex : "/>
 </div>
 
 
 
      </div>
     </div>
 

     <div className="row mt-3">
     <div className="col-12 col-md-6 col-lg-6 col-xl-6">
     
      
 <div className="form-group">
 <label for="exampleInputEmail1">Reflection Fee <span style={{color:"red"}}>*</span></label>
 <input value={buyRefFee} onChange={(e)=>setBuyRefFee(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex :"/>
 </div>

      </div>
 
      <div className="col-12 col-md-6 col-lg-6 col-xl-6">
 
    
 <div className="form-group">
 <label for="exampleInputEmail1">Marketing fee (%) <span style={{color:"red"}}>*</span></label>
 <input value={buyMarkFee} onChange={(e)=>setBuyMarkFee(e.target.value)} type="number" className="form-control preinput" id="exampleInputEmail1"  placeholder="Ex : "/>
 </div>
 
 
 
      </div>
     </div>


     {
            isCo() != null ? isCo():
            <button className="nextBtn" onClick={createBuyback} style={{width:'128px'}}>Create Token</button>
 
        }
 
 </div>
        </>
    )
}

export default Buyba;