import React, {useState, createContext,   useContext} from 'react';
import { ContextApi } from '../../../utilities';
export const Dat = createContext();
const PreSecondPage = ({pdn, pBtn  })=>{
     const {getToken,abistatus,tokenDecimals, isCo, setGetToken, tokenName, tokenSymbol, tokenSupply} = useContext(ContextApi);
     
const [datas , setDatas] = useState({
  text01:'',
  
});

const [bal, setBal] = useState(null)


    return(
        <>
     
        <Dat.Provider value={datas}>
         <div className="col-12 col-md-12 col-lg-8 col-xl-8  rightPresale" style={{display:pdn}}>
         <p>{"(*)"} is required field</p>
         <form>
  <div className="form-group">
    <label for="exampleInputEmail1">Token Address <span style={{color:"red"}}>*</span></label>
    <input required type="text" className="form-control preinput" id="valinput" value={getToken} onChange={(e)=>setGetToken(e.target.value)}  placeholder="Ex 0X..."/>
  </div>

      
         {
  abistatus == true?
  <div style={{marginTop:'20px',width:'100%',flexDirection:'column', justifyContent:'space-between',display:'flex'}} className="w-full flex justify-between">
  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full justify-between">
    <span>Token Name</span> <span>{tokenName}</span>
  </div>

  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full my-2 justify-between">
    <span>Token Symbol</span> <span>{tokenSymbol}</span>
  </div>
  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full my-2 justify-between">
    <span>Token Decimals</span> <span>{tokenDecimals}</span>
  </div>

  <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full my-2 justify-between">
    <span>Total Supply</span> <span>{tokenSupply}</span>
  </div>

  {/* <div style={{width:'100%', margin:'5px 0px',padding:'0px 6px',justifyContent:'space-between', display:'flex'}} className="w-full my-2 justify-between">
    <span>your Balance</span> <span>{tokenBalance}</span>
  </div> */}

</div>:''
 }
      
    <div className="form-group mt-5" >
    <label for="currency">Currency</label>
  <select className="form-select  preinput" aria-label="Default select example">
  <option selected>BNB</option>
  {/* <option value="1">BUSD</option> */}
</select>
    </div>
</form>
<p className="mt-4 text07">
Users will pay with BNB for your token
</p>
{/* <label className="mt-3 mb-3">Fee Option</label>
<h6 style={{display:"flex", alignItems:'center'}}><input style={{marginLeft:'8px'}} type="radio"/>  2.5% BSC raised only</h6>
 
<h6 style={{display:"flex", alignItems:'center'}}><input style={{marginLeft:'8px'}} type="radio"/>  1.5% BSC raised + 1.5% token raised</h6>
  */}
 <p className="mt-3" style={{color:'#47a1ff',fontSize:'13px' ,marginBottom:'0px'}}>
 Make sure the token has 'Exclude transfer fee' function if it has transfer fees.
  </p>
 
{
  isCo() == null? <>

   {
    tokenName != ''? <button onClick={()=> pBtn()} className="nextBtn" style={{width:'150px'}}>Next</button>:<button className="nextBtn" disabled style={{width:'150px', background:'gray'}}>Next</button>
   }
  </>
:isCo()
}

    </div>
    </Dat.Provider>
   
        </>
    )
}

export default PreSecondPage;
