import React, {useState,useEffect, useContext} from'react';
import Nav from '../partial/Nav';
import WalNav from '../partial/WalNav';
import { Dat } from '../partial/Presale/PreSecodPage';
import {ContextApi} from '../../utilities';
import { ethers } from 'ethers';
import PresaleAbi from '../../abis/Presale.json'
import { useAccount, useWriteContract } from 'wagmi';
import ConnectButton from '../../walletBtn';
import YouTube from 'react-youtube';
const Detail = ()=>{
  const {address} = useAccount();
  const {writeContractAsync} = useWriteContract();
   const PresaleABI = PresaleAbi.abi;
  const [softcat, setSoftcap] = useState('');
  const [buyVal, setBuyVal] = useState('')
  const [hardcap, setHardcap] = useState('');
  
  const [maxBuy, setMaxBuy] = useState('');
  const [minBuy, setMinBuy] = useState('')
  const [raised, setRaised] = useState('')
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenSupply, setTokenSupply] = useState('')
  const [pool, setPool] = useState('')
  const [tokenRate, setTokenRate] = useState('')
  const [bnbRaised, setBnbRaised] = useState(0)
  const [log, setLog] = useState('')
  const [ban, setBan] = useState('')
  const [des, setDes] = useState('')
  const [web, setWeb] = useState('')
  const [yout , setYout]  = useState('');

  const [sDay, setSday] = useState('');
  const [eDay, setEday] = useState('');
  const [sHour, setShour] = useState('');
  const [eHour, setEhour] = useState('')

  const [sMinute, setSminute] = useState('')
  const [eMinute, setEminute] = useState('')

  const [sSecond, setSsecond] = useState('')
  const [eSecond, setEsecond] = useState('')
  const [token,setToken] = useState('')

 const [dis, setDis] = useState('');
 const [white, setWhite] = useState('')
 const [face, setFace] = useState('')
 const [twit, setTwit] = useState('');
 const [git, setGit] = useState('')
 const [tel , setTel] = useState('')
const [isCan, setIsCan] = useState(null)
 const [remainTime, setRemainTime] = useState()
  useEffect(()=>{
    const raisedCalculate =  (softcat*raised);
      setBnbRaised(raisedCalculate?.toFixed(2))
  },[maxBuy])

    const [mowner, setMowner] = useState('')
   
  useEffect(()=>{
     const getUniquePresale = async ()=>{
      try{
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchUrl = new URLSearchParams(url.search)
        const contractAddress = searchUrl.get('token');
        setPool(contractAddress)
        setMowner(searchUrl.get('owner'));
        
        const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org')
        const contract = new ethers.Contract(contractAddress, PresaleABI, provider)
      
       const tokenName1 = await contract.getTokenName();
       setTokenName(tokenName1)
       const tokenSupply1 = await contract.getTokenTotalSupply();
       setTokenSupply(ethers.utils.formatEther(tokenSupply1))
       const symbol = await contract.getTokenSymbol();
       setTokenSymbol(symbol)
       const rate = await contract.getTokenRate();
       setTokenRate(ethers.utils.formatEther(rate))
       const soft = await contract.getSoftCap();
       setSoftcap(ethers.utils.formatEther(soft))
       const hard = await contract.getHardCap();
       setHardcap(ethers.utils.formatEther(hard));
       const rais = await contract.totalRaised();
       setRaised(ethers.utils.formatEther(rais));
       const max = await contract.getMaxBuy();
       const min = await contract.getMinBuy();
       setMinBuy(ethers.utils.formatEther(min))
       setMaxBuy(ethers.utils.formatEther(max));
       const gettok = await contract.getTokenAddress()
       setToken(gettok)
       const meta = await contract.getMetadata();
       setLog(meta?.split('~')[0]);
       setBan(meta?.split('~')[1]);
       setWeb(meta?.split('~')[2]);
       setDes(meta?.split('~')[10])
       setYout(meta?.split('~')[3])
       setDis(meta?.split(`~`)[4])
       setWhite(meta?.split(`~`)[5])
       setFace(meta?.split(`~`)[6])
       setTwit(meta?.split(`~`)[7])
       setGit(meta?.split(`~`)[8])
       setTel(meta?.split(`~`)[9])
      
       const isCancel = await contract.getIsCancel();
       setIsCan(isCancel)
       const startTime = await contract.getStartTime();
       const endTime = await contract.getEndTime();
     
       const startBn = ethers.BigNumber.from(startTime);
     
       const startFormatTime = new Date(startBn.toNumber() * 1000);
      
       const timeDifferenceInSeconds = Math.floor((startFormatTime.getTime() - Date.now()) / 1000);
 
       const days = Math.floor(timeDifferenceInSeconds / (24 * 3600));
       const hours = Math.floor((timeDifferenceInSeconds % (24 * 3600)) / 3600);
       const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
       const seconds = Math.floor(timeDifferenceInSeconds % 60);
 
       // Format the time remaining string
       const timeRemainingString = `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setRemainTime(timeRemainingString)




      

          const interval = setInterval(()=>{
          
        
            const startBn = ethers.BigNumber.from(startTime);
            const endBn = ethers.BigNumber.from(endTime);
          
            const startFormatTime = new Date(startBn.toNumber() * 1000);
            const endFormatTime = new Date(endBn.toNumber() * 1000);
            const timeDifferenceInSeconds = Math.floor((startFormatTime.getTime() - Date.now()) / 1000);
           
            const days = Math.floor(timeDifferenceInSeconds / (24 * 3600));
                 const hours = Math.floor((timeDifferenceInSeconds % (24 * 3600)) / 3600);
                 const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
                 const seconds = Math.floor(timeDifferenceInSeconds % 60);
                 setSsecond(seconds)
                 setSday(days);
                 setShour(hours);
                 setSminute(minutes);
     
                    
     
                 const timeDifferenceInSecondsend = Math.floor((endFormatTime.getTime() - Date.now()) / 1000);
                 const dayse = Math.floor(timeDifferenceInSecondsend / (24 * 3600));
                 const hourse = Math.floor((timeDifferenceInSecondsend % (24 * 3600)) / 3600);
                 const minutese = Math.floor((timeDifferenceInSecondsend % 3600) / 60);
                 const secondse = Math.floor(timeDifferenceInSecondsend % 60);
                 setEsecond(secondse)
                 setEday(dayse)
                 setEhour(hourse);
                 setEminute(minutese);
     
     
       
           
     
     
     
          },1000)

         ( ()=> clearInterval(interval))



      
     
      }catch(error){
        console.log(error)
      } }
     getUniquePresale();
      
  },[])



   


    


      const BuywithBNB = async ()=>{
        try{
          const tx = await writeContractAsync({
            address:pool,
            abi:PresaleABI, 
            functionName:'Bid',
            value:ethers.utils.parseUnits(buyVal, "ether")
          })
           
          alert("you've successfully bought")

        }catch(error){
          if(error){
            alert("make sure you buying with minimum buy or check your balance")
          }
          console.log(error)
        }
      }

      const finalize = async()=>{
        try{

        const tx = await writeContractAsync({
            address:pool,
            abi:PresaleABI,
            functionName:'Finalize'
          })
        }catch(error){
          if(error){
            alert("something went wrong not you can't finalize")
          }
          console.log(error, '')
        }
      }
      const widthdraw = async ()=>{
        try{
       const tx = await writeContractAsync({
        address:pool,
        abi:PresaleABI,
        functionName:'withdraw'
       })
       console.log(tx, 'message')

        }catch(error){
          if(error){
            alert('you can not withdraw until finish presale')
          }
          console.log(error, 'withdraw error')
        }
      }


      const cancel = async ()=>{
        try{
       const tx = await writeContractAsync({
        address:pool,
        abi:PresaleABI,
        functionName:'Cancel',
       })
        }catch(error){
          if(error){
            alert("looks you're not owner of this pool or something went wrong")
          }
          console.log(error, 'error from cancel function')
        }
      }

const opts={
  width:'100%',
  height:'300px'

}

    return(
        <>
          <div className="d-flex">
       <Nav/>

<section className="home_contents">
    <WalNav/>
   <div className="container presaleWrap mx-auto row">
   <div id="detailWrapi" className="col-12 col-md-12 col-lg-7 col-xl-7  rightPresale">
      <div className="banner">
        <img src={/.(jpg|jpeg|png|gif|svg)$/i.test(ban) ? ban : 'images/loading.gif'}  alt="bng"/>

        <div className="bnSub">
            <div className="subLogo">
                <img src={/.(jpg|jpeg|png|gif|svg)$/i.test(log) ? log : 'images/loading.gif'} alt="logos" style={{width:"50px" ,height:"50px" , borderRadius:"50%"}}/>
                <div className="wrapLog">
                {
    isCan ? (
      <span style={{background:'red', fontSize:'13px', padding:'1px 9px', borderRadius:'30px'}} className="fail">Closed</span>
    ) : (
      remainTime?.split(':').map(Number).some(val => val < 0) ? (
        <span className="success">Live</span>
      ) : (
        <span className="warning">Upcoming</span>
      )
    )
  }
                    <h5>{tokenName}</h5>
                    <div className="socialMedd">
                      {
                        web != ""?  <a href={web}><i className="fa-solid fa-earth-americas"></i></a>:''
                      }

                        {
                        dis != ""?      <a href={dis}><i className="fa-brands fa-discord"></i></a>:''
                      }
                        {
                        white != ""?      <a href={white}><i className="fa-regular fa-file"></i></a>:''
                      }  {
                        face != ""?      <a href={face}><i className="fa-brands fa-facebook"></i></a>:''
                      } {
                        twit != ""?      <a href={twit}><i className="fa-brands fa-twitter"></i></a>:''
                      }{
                        git != ""?      <a href={git}><i className="fa-brands fa-github"></i></a>:''
                      }{
                        tel != ""?      <a href={tel}><i className="fa-brands fa-telegram"></i></a>:''
                      }
                       
                     
                     
                    </div>
                </div>
            </div>

            <p style={{height:'140px', overflowY:'auto'}} className="mt-4">
            {des}
           
           
            </p>

        </div>
      </div>

      <div style={{width:'100%', marginTop:'150px', visibility:'hidden', height:'20px',  borderRadius:'20px'}}>
      <YouTube videoId={yout?.split('=')[1]} opts={opts}  />
      </div>
 


<div style={{marginTop:'40px'}} className="wrn01">

<div className="dataWrap">
            <span>pool address</span>
            <span ><a href={`https://bscscan.com/address/${pool}`} style={{color:'red'}}>{pool}</a>
              <p className="bnb_address_text">Do not send BNB to this address</p>
            </span>
            
          </div>
         

          <div className="dataWrap">
            <span>Token address</span>
            <span><a href={`https://bscscan.com/address/${token}`} style={{color:'red'}}> <img src="images/tik.png" style={{width:'22px', height:'22px'}} alt="tik"/> {token}</a></span>
          </div>

          <div className="dataWrap">
            <span>Category</span>
            <span><p  style={{padding:'3px 7px ', borderRadius:'10px', background:'rgb(107 70 155 / 31%)', color:'6B469B', marginBottom:'0px'}}>Launchpad</p></span>
          </div>
           
         
          <div className="dataWrap">
            <span>Token name</span>
            <span>{tokenName}</span>
          </div>

          <div className="dataWrap">
            <span>Token Symbol</span>
            <span>{tokenSymbol}</span>
          </div>


       



          <div className="dataWrap">
            <span>Token Decimals</span>
            <span>18</span>
          </div>
          <div className="dataWrap">
            <span>Total Supply</span>
            <span>{tokenSupply}</span>
          </div>
          {/* <div className="dataWrap">
            <span>Tokens for Presale</span>
            <span>Tokens for Presale</span>
          </div> */}

          {/* <div className="dataWrap">
            <span>Hard Cap </span>
            <span>{hardcap} BNB</span>
          </div> */}
            <div className="dataWrap">
            <span>Soft Cap</span>
            <span>{softcat} BNB</span>
          </div>
          {/* <div className="dataWrap">
            <span>Listing On</span>
            <span style={{color:'red'}}>PancakeSwap Testnet</span>
          </div> */}
          
</div>







 
   </div>
    <div className="col-12 col-md-12 col-lg-5 col-xl-5 row " style={{padding:"0px 33px"}}>
    <div className="cwrapper">
    
    {
     sDay != 0 && sHour != 0 && sMinute != 0? <div className="countDownWrapper">
      <h3 className="text-start" style={{fontSize:'15px'}}> Ends In</h3>
      <div className="timerWrap">
       <div className="timerR">
        <div className="theTimer">
          <span>{eDay}</span>
          <span className="timerText">Days</span>
        </div>
       
       </div>

       <div className="timerR">
        <div className="theTimer">
          <span>{eHour}</span>
          <span className="timerText">Hours</span>
        </div>
       
       </div>

       <div className="timerR">
        <div className="theTimer">
          <span>{eMinute}</span>
          <span className="timerText">Minutes</span>
        </div>
       
       </div>

       <div className="timerR">
        <div className="theTimer">
          <span>
       {eSecond}
          </span>
          <span className="timerText">Seconds</span>
        </div>
       
       </div>
      </div>
   {/* <Countdown date={Date.now() + 900000002} /> */}
   </div>: 

  sDay == 0 && sHour == 0 && sMinute==0?
<div className="countDownWrapper">
<h3 className="text-start" style={{fontSize:'15px'}}>Starts In</h3>
<div className="timerWrap">
 <div className="timerR">
  <div className="theTimer">
    <span>{sDay}</span>
    <span className="timerText">Days</span>
  </div>
 
 </div>

 <div className="timerR">
  <div className="theTimer">
    <span>{sHour}</span>
    <span className="timerText">Hours</span>
  </div>
 
 </div>

 <div className="timerR">
  <div className="theTimer">
    <span>{sMinute}</span>
    <span className="timerText">Minutes</span>
  </div>
 
 </div>

 <div className="timerR">
  <div className="theTimer">
    <span>
 {sSecond}
    </span>
    <span className="timerText">Seconds</span>
  </div>
 
 </div>
</div>
{/* <Countdown date={Date.now() + 900000002} /> */}
</div>:''
    }

     <div className="progressWrapi1">
    <div className="progressLabel1">
        <span>Progress:</span>
        <span>{bnbRaised}%</span>
    </div>
     <div className="progressMain1">
        <div style={{width:`${bnbRaised}%`}} className="progressSub1">
            
        </div>
     </div>
    <div className="progressLabel1">
        <span>{raised} BNB</span>
        <span>{softcat} BNB</span>
    </div>
</div>

    <div className="contributionAmmount mt-5">
    Contribute Amount
    <input value={buyVal} onChange={(e)=> setBuyVal(e.target.value)}  type="number" placeholder={`BNB MAX BUY ${maxBuy} & MIN BUY ${minBuy}`}/>



  

{
    isCan ? (
      <span  className=""></span>
    ) : (
      remainTime?.split(':').map(Number).some(val => val < 0) ? (
        <>
           {
      address?<>
      {
        buyVal != "" ? <button onClick={BuywithBNB} style={{borderRadius:"15px",  marginTop:"20px" , padding:'10px 5px', background:"#47a1ff", color:'#fff', border:'none', fontSize:'14px'}} className="cb">Buy With BNB</button>:<button disabled style={{borderRadius:"15px",  marginTop:"20px" , padding:'10px 5px', background:"gray", color:'#fff', border:'none', fontSize:'14px'}} className="cb">Buy With BNB</button>
      }
      </>:
     <div style={{width:'100%',margin:'0px auto', padding:'10px 0px'}}> <ConnectButton/></div>
     }
        </>
      ) : (
        <span className=""></span>
      )
    )
  }
    
  
  

  
    </div>
    </div>



    <div className="cwrapper mt-4">

     <div className="statusRow">
        <span>Status</span>
        {
    isCan ? (
      <span style={{background:'red', fontSize:'13px', padding:'1px 9px', borderRadius:'30px'}} className="fail">Closed</span>
    ) : (
      remainTime?.split(':').map(Number).some(val => val < 0) ? (
        <span className="success">Live</span>
      ) : (
        <span className="warning">Upcoming</span>
      )
    )
  }
     </div>
     <div className="statusRow">
        <span>Current Rate</span>
        <span className="right_statas">
        1 BNB = {tokenRate}<br/> {tokenSymbol}
        </span>
     </div>
 
     {
      mowner == address?<>
           <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center'}} className="statusRow">
       <button onClick={finalize} style={{width:'90%', border:'1px solid gray', borderRadius:'15px',fontWeight:'bold', height:'50px'}}>Finalize</button>
     </div>
     <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center'}} className="statusRow">
       <button onClick={widthdraw} style={{width:'90%', border:'1px solid gray', borderRadius:'15px',fontWeight:'bold', height:'50px'}}>Withdraw</button>
     </div>
     <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center'}} className="statusRow">
       <button onClick={cancel} style={{width:'90%', border:'1px solid gray', borderRadius:'15px',fontWeight:'bold', height:'50px'}}>Cancel</button>
     </div>
      </>:''
     }

     {/* <div className="statusRow">
        <span>My Contribution	</span>
        <span className="right_statas">0.0 BNB</span>
     </div>
     <div className="statusRow">
        <span>Total Contributors</span>
        <span className="right_statas">1</span>
     </div> */}
    </div>
    </div>     
     
</div>
    </section>
    </div>
        </>
    )
}


export default Detail;